import { Col, DatePicker, Select } from "antd";
import { DataSnapshot } from "firebase/database";
import { Fragment, useContext } from "react";
import { getDatabase } from "firebase/database";
import { onValue } from "firebase/database";
import { ref } from "firebase/database";
import { Row } from "antd";
import { Solicitud } from "../../model/solicitud";
import { Typography } from "antd";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import ErrorComponent from "../util/error.component";
import Global from "../../global/global";
import LoadComponent from "../util/load.component";
import TablaSolicitudes from "../widgets/tabla_solicitudes";
import { APIUsuario } from "../../api/api_usuario";
import UsuarioContext from "../../provider/usuario-provider";
import locale from "antd/es/date-picker/locale/es_ES";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

function ReportePage({ _setRoutes }: { _setRoutes: Function }) {
  const [_mounted, _setMounted] = useState<any>(null);
  const [_solicitudes, _setSolicitudes] = useState<Solicitud[]>([]);
  const [_loading, _setLoading] = useState<boolean>(true);
  const [_solicitudesTemp, _setSolicitudesTemp] = useState<Solicitud[]>([]);
  const [_asesores, _setAsesores] = useState<Array<string>>([]);
  const [_clientes, _setClientes] = useState<Array<string>>([]);
  const [_usuario, _setUsuario] = useState<Usuario>(new Usuario({}));
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { Title } = Typography;
  const { Option } = Select;
  let _navigate = useNavigate();
  const { Panel } = Collapse;

  const _getAsesores = (_solicitudes: Solicitud[]) => {
    let _asesores: string[] = [];
    _solicitudes.forEach((_solicitud: Solicitud, _index: number) => {
      _asesores.push(_solicitud.creadorNombre!);
    });
    _asesores.sort((a: string, b: string) => a.localeCompare(b) ?? 0);
    _setAsesores(Global._deleteDuplicated(_asesores));
  };

  const _getClientes = (_solicitudes: Solicitud[]) => {
    let _clientes: string[] = [];
    _solicitudes.forEach((_solicitud: Solicitud, _index: number) => {
      _clientes.push(_solicitud.clienteNombre!);
    });
    _clientes.sort((a: string, b: string) => a.localeCompare(b) ?? 0);
    _setClientes(Global._deleteDuplicated(_clientes));
  };

  const _getSolicitudes = useCallback(async (_usuario) => {
    _setLoading(true);
    let _solicitudes: Solicitud[] = [];
    if (_usuario.perfil === Global.PERFILES[0]) {
      onValue(
        ref(getDatabase(), `solicitudes/${_usuario.id}`),
        (_snapshot: DataSnapshot) => {
          _solicitudes = [];
          if (_snapshot.exists()) {
            _snapshot.forEach((_document: any) => {
              let _solicitud: Solicitud = new Solicitud(_document.val());
              _solicitud.solicitudId = _document.ref._path.pieces_[2];
              _solicitudes.push(_solicitud);
            });
          }
          _solicitudes.sort(
            (a: Solicitud, b: Solicitud) =>
              b.solicitudFechaCreacion?.localeCompare(
                a.solicitudFechaCreacion!
              ) ?? 0
          );
          _setSolicitudes(_solicitudes);
          _setSolicitudesTemp(_solicitudes);
          _getClientes(_solicitudes);
        }
      );
    } else {
      onValue(ref(getDatabase(), `solicitudes`), (_snapshot: DataSnapshot) => {
        _solicitudes = [];
        if (_snapshot.exists()) {
          _snapshot.forEach((_documents: any) => {
            _documents.forEach((_data: any) => {
              let _solicitud: Solicitud = new Solicitud(_data.val());
              _solicitud.solicitudId = _data.ref._path.pieces_[2];
              if (
                _usuario.perfil === Global.PERFILES[1] ||
                _usuario.perfil === Global.PERFILES[5]
              ) {
                _solicitudes.push(_solicitud);
              } else if (
                _usuario.perfil === Global.PERFILES[2] &&
                (_solicitud.solicitudEstado === Global.ESTADOS[1] ||
                  _solicitud.solicitudEstado === Global.ESTADOS[2] ||
                  _solicitud.solicitudEstado === Global.ESTADOS[3]) &&
                (_solicitud.solicitudTipo === Global.TIPOS[1] ||
                  _solicitud.solicitudTipo === Global.TIPOS[2])
              ) {
                _solicitudes.push(_solicitud);
              } else if (
                (_usuario.perfil === Global.PERFILES[3] ||
                  _usuario.perfil === Global.PERFILES[4]) &&
                (_solicitud.solicitudEstado === Global.ESTADOS[1] ||
                  _solicitud.solicitudEstado === Global.ESTADOS[2] ||
                  _solicitud.solicitudEstado === Global.ESTADOS[3]) &&
                _solicitud.solicitudTipo === Global.TIPOS[2]
              ) {
                _solicitudes.push(_solicitud);
              }
            });
          });
          _solicitudes.sort(
            (a: Solicitud, b: Solicitud) =>
              b.solicitudFechaCreacion?.localeCompare(
                a.solicitudFechaCreacion!
              ) ?? 0
          );
          _setSolicitudes(_solicitudes);
          _setSolicitudesTemp(_solicitudes);
          _getAsesores(_solicitudes);
          _getClientes(_solicitudes);
        }
      });
    }
    _setLoading(false);
  }, []);

  const _getCurrentUser = useCallback(async () => {
    _apiUsuario
      ._getCurrenUser()
      .then(async (_user: any) => {
        if (_user) {
          let _usuario: Usuario = await _apiUsuario._getUser(_user.uid);
          _setUsuario(_usuario);
          _getSolicitudes(_usuario);
        } else {
          _navigate("/login");
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }, [_apiUsuario, _getSolicitudes, _navigate]);

  const _rowSelection = {
    onChange: (_solicitudId: React.Key[]) => {
      _navigate(`/detalle/${_solicitudId}`);
    },
  };

  const _onChangeAsesor = (_data: string) => {
    _setLoading(true);
    if (!_data) {
      _setSolicitudes(_solicitudesTemp);
    } else {
      let _solicitudes: Solicitud[] = [];
      _solicitudesTemp.forEach((_solicitud: Solicitud) => {
        let _asesor: string = _solicitud.creadorNombre!;
        if (_asesor.indexOf(_data) > -1) {
          _solicitudes.push(_solicitud);
        }
      });
      _setSolicitudes(_solicitudes);
    }
    _setLoading(false);
  };

  const _onChangeCliente = (_data: string) => {
    _setLoading(true);
    if (!_data) {
      _setSolicitudes(_solicitudesTemp);
    } else {
      let _solicitudes: Solicitud[] = [];
      _solicitudesTemp.forEach((_solicitud: Solicitud) => {
        let _cliente: string = _solicitud.clienteNombre!;
        if (_cliente.indexOf(_data) > -1) {
          _solicitudes.push(_solicitud);
        }
      });
      _setSolicitudes(_solicitudes);
    }
    _setLoading(false);
  };

  const _changeDate = (_moment: any, _date: any) => {
    _setLoading(true);
    if (!_date) {
      _setSolicitudes(_solicitudesTemp);
    } else {
      let _solicitudes: Solicitud[] = [];
      _solicitudesTemp.forEach((_solicitud: Solicitud) => {
        let _fecha: string = _solicitud.solicitudFechaCreacion?.substring(
          0,
          10
        )!;
        console.log(_fecha);
        if (_fecha.indexOf(_date) > -1) {
          _solicitudes.push(_solicitud);
        }
      });
      _setSolicitudes(_solicitudes);
    }
    _setLoading(false);
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    _setRoutes(["Inicio", "Reporte"]);
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser, _setRoutes]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return _usuario.perfil ? (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3} className="titulo">
            Lista de solicitudes registradas
          </Title>
          <Title level={5} className="subtitulo">
            A continuaci&oacute;n se muestra la lista de solicitudes registradas
          </Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Collapse
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={["1"]}
          >
            <Panel header="Filtros y búsqueda" key="1">
              <Row>
                {_usuario.perfil === Global.PERFILES[0] ? (
                  ""
                ) : (
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <div style={{ padding: 10 }}>
                      <label>&nbsp;Asesores:</label>
                      <Select
                        placeholder="Seleccionar asesor"
                        style={{ width: "100%", margin: "5px 1%" }}
                        onChange={_onChangeAsesor}
                        showSearch
                        allowClear
                      >
                        {_asesores.map((_asesor: String, _index: number) => (
                          <Option
                            key={`${_asesor}_${_index}`}
                            value={_asesor as string}
                          >
                            {_asesor}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                )}
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={_usuario.perfil === Global.PERFILES[0] ? 12 : 8}
                  xl={_usuario.perfil === Global.PERFILES[0] ? 12 : 8}
                  xxl={_usuario.perfil === Global.PERFILES[0] ? 12 : 8}
                >
                  <div style={{ padding: 10 }}>
                    <label>&nbsp;Clientes:</label>
                    <Select
                      placeholder="Seleccionar cliente"
                      style={{ width: "100%", margin: "5px 1%" }}
                      onChange={_onChangeCliente}
                      showSearch
                      allowClear
                    >
                      {_clientes.map((_cliente: String, _index: number) => (
                        <Option
                          key={`${_cliente}_${_index}`}
                          value={_cliente as string}
                        >
                          {_cliente}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={_usuario.perfil === Global.PERFILES[0] ? 12 : 8}
                  xl={_usuario.perfil === Global.PERFILES[0] ? 12 : 8}
                  xxl={_usuario.perfil === Global.PERFILES[0] ? 12 : 8}
                >
                  <div style={{ padding: 10 }}>
                    <label>&nbsp;Fecha:</label>
                    <DatePicker
                      style={{ width: "100%", margin: "4px 1%" }}
                      allowClear
                      locale={locale}
                      onChange={_changeDate}
                      format={"DD/MM/YYYY"}
                    />
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <TablaSolicitudes
            _listaSolicitudes={_solicitudes}
            _usuario={_usuario}
            _rowSelection={_rowSelection}
            _loading={_loading}
          />
        </Col>
      </Row>
    </Fragment>
  ) : (
    <LoadComponent />
  );
}

export default ReportePage;

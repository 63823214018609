export interface SolicitudDataI {
  clienteCodigo?: string | null;
  clienteLineaCredito?: number | null;
  clienteLineaCreditoUsada?: number | null;
  clienteNombre?: string | null;
  clienteSituacionSAP?: string | null;
  clienteSituacionSentinel?: string | null;
  creadorCodigo?: string | null;
  creadorNombre?: string | null;
  creadorSede?: string | null;
  solicitudCondicionPago?: string | null;
  solicitudEstado?: string | null;
  solicitudFechaCreacion?: string | null;
  solicitudGarantia?: string | null;
  solicitudId?: string | null;
  solicitudObservaciones?: string | null;
  solicitudPDFCotizacion?: string | null;
  solicitudPlazo?: number | null;
  solicitudPorcentaje?: number | null;
  solicitudTipo?: string | null;
  solicitudTotalCotizacion?: number | null;
  solicitudInformeFinal?: string | null;
}

export class Solicitud implements SolicitudDataI {
  clienteCodigo?: string | null;
  clienteLineaCredito?: number | null;
  clienteLineaCreditoUsada?: number | null;
  clienteNombre?: string | null;
  clienteSituacionSAP?: string | null;
  clienteSituacionSentinel?: string | null;
  creadorCodigo?: string | null;
  creadorNombre?: string | null;
  creadorSede?: string | null;
  solicitudCondicionPago?: string | null;
  solicitudEstado?: string | null;
  solicitudFechaCreacion?: string | null;
  solicitudGarantia?: string | null;
  solicitudId?: string | null;
  solicitudObservaciones?: string | null;
  solicitudPDFCotizacion?: string | null;
  solicitudPlazo?: number | null;
  solicitudPorcentaje?: number | null;
  solicitudTipo?: string | null;
  solicitudTotalCotizacion?: number | null;
  solicitudInformeFinal?: string | null;

  constructor(data: SolicitudDataI) {
    this.clienteCodigo = data.clienteCodigo;
    this.clienteLineaCredito = data.clienteLineaCredito;
    this.clienteLineaCreditoUsada = data.clienteLineaCreditoUsada;
    this.clienteNombre = data.clienteNombre;
    this.clienteSituacionSAP = data.clienteSituacionSAP;
    this.clienteSituacionSentinel = data.clienteSituacionSentinel;
    this.creadorCodigo = data.creadorCodigo;
    this.creadorNombre = data.creadorNombre;
    this.creadorSede = data.creadorSede;
    this.solicitudCondicionPago = data.solicitudCondicionPago;
    this.solicitudEstado = data.solicitudEstado;
    this.solicitudFechaCreacion = data.solicitudFechaCreacion;
    this.solicitudGarantia = data.solicitudGarantia;
    this.solicitudId = data.solicitudId;
    this.solicitudObservaciones = data.solicitudObservaciones;
    this.solicitudPDFCotizacion = data.solicitudPDFCotizacion;
    this.solicitudPlazo = data.solicitudPlazo;
    this.solicitudPorcentaje = data.solicitudPorcentaje;
    this.solicitudTipo = data.solicitudTipo;
    this.solicitudTotalCotizacion = data.solicitudTotalCotizacion;
    this.solicitudInformeFinal = data.solicitudInformeFinal;
  }

  _toMap() {
    return {
      clienteCodigo: this.clienteCodigo || null,
      clienteLineaCredito: this.clienteLineaCredito || null,
      clienteLineaCreditoUsada: this.clienteLineaCreditoUsada || null,
      clienteNombre: this.clienteNombre || null,
      clienteSituacionSAP: this.clienteSituacionSAP || null,
      clienteSituacionSentinel: this.clienteSituacionSentinel || null,
      creadorCodigo: this.creadorCodigo || null,
      creadorNombre: this.creadorNombre || null,
      creadorSede: this.creadorSede || null,
      solicitudCondicionPago: this.solicitudCondicionPago || null,
      solicitudEstado: this.solicitudEstado || null,
      solicitudFechaCreacion: this.solicitudFechaCreacion || null,
      solicitudGarantia: this.solicitudGarantia || null,
      solicitudId: this.solicitudId || null,
      solicitudObservaciones: this.solicitudObservaciones || null,
      solicitudPDFCotizacion: this.solicitudPDFCotizacion || null,
      solicitudPlazo: this.solicitudPlazo || null,
      solicitudPorcentaje: this.solicitudPorcentaje || null,
      solicitudTipo: this.solicitudTipo || null,
      solicitudTotalCotizacion: this.solicitudTotalCotizacion || null,
      solicitudInformeFinal: this.solicitudInformeFinal || null,
    };
  }
}

import { Solicitud } from "../../model/solicitud";
import { Table } from "antd";
import { Usuario } from "../../model/usuario";
import Global from "../../global/global";

function TablaSolicitudes({
  _listaSolicitudes,
  _usuario,
  _rowSelection,
  _loading,
}: {
  _listaSolicitudes: Solicitud[];
  _usuario: Usuario;
  _rowSelection: {};
  _loading: boolean;
}) {
  return (
    <Table
      loading={_loading}
      pagination={{
        position: ["bottomCenter"],
        simple: false,
        size: "small",
      }}
      bordered
      key="tablaSolicitudes"
      rowKey="solicitudId"
      rowSelection={{
        type: "radio",
        ..._rowSelection,
      }}
      columns={[
        {
          title: "Estado",
          key: "solicitudEstado",
          dataIndex: "solicitudEstado",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudEstado!.localeCompare(b.solicitudEstado!),
          showSorterTooltip: false,
          filters: Global.ESTADOS.map((_estado: string) => {
            return { text: _estado, value: _estado };
          }),
          onFilter: (_estado: any, record: Solicitud) =>
            record.solicitudEstado!.indexOf(_estado) === 0,
          render: (_value, _, __) => {
            return Global._renderEstado(_value);
          },
        },
        {
          title: "Tipo",
          key: "solicitudTipo",
          dataIndex: "solicitudTipo",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudTipo!.localeCompare(b.solicitudTipo!),
          showSorterTooltip: false,
          filters: Global.TIPOS.map((_tipo: string) => {
            return { text: _tipo, value: _tipo };
          }),
          onFilter: (_tipo: any, record: Solicitud) =>
            record.solicitudTipo!.indexOf(_tipo) === 0,
          render: (value, _, __) => {
            return Global._renderTipo(value);
          },
        },
        {
          title: "DNI/RUC",
          key: "clienteCodigo",
          dataIndex: "clienteCodigo",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.clienteCodigo!.localeCompare(b.clienteCodigo!),
          showSorterTooltip: false,
          className:
            _usuario.perfil === Global.PERFILES[0] ? "" : "hide-column",
          responsive: ["md"],
        },
        {
          title: "Asesor Nombres",
          key: "creadorNombre",
          dataIndex: "creadorNombre",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.creadorNombre!.localeCompare(b.creadorNombre!),
          showSorterTooltip: false,
          className:
            _usuario.perfil === Global.PERFILES[0] ? "hide-column" : "",
          responsive: ["md"],
        },
        {
          title: "Sede",
          key: "creadorSede",
          dataIndex: "creadorSede",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.creadorSede!.localeCompare(b.creadorSede!),
          showSorterTooltip: false,
          filters: Global.SEDES.map((_sede: string) => {
            return { text: _sede, value: _sede };
          }),
          onFilter: (_sede: any, record: Solicitud) =>
            record.creadorSede!.indexOf(_sede) === 0,
          className:
            _usuario.perfil === Global.PERFILES[0] ? "hide-column" : "",
          responsive: ["md"],
        },
        {
          title: "Cliente",
          key: "clienteNombre",
          dataIndex: "clienteNombre",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.clienteNombre!.localeCompare(b.clienteNombre!),
          showSorterTooltip: false,
          responsive: ["md"],
        },
        {
          title: "LC",
          key: "clienteLineaCredito",
          dataIndex: "clienteLineaCredito",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.clienteLineaCredito! - b.clienteLineaCredito!,
          showSorterTooltip: false,
          render: (value, _, __) => {
            return `S/${value}`;
          },
          className: "hide-column",
        },
        {
          title: "LCU",
          key: "clienteLineaCreditoUsada",
          dataIndex: "clienteLineaCreditoUsada",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.clienteLineaCreditoUsada! - b.clienteLineaCreditoUsada!,
          showSorterTooltip: false,
          render: (value, _, __) => {
            return `S/${value}`;
          },
          className: "hide-column",
        },

        {
          title: "Situación SAP",
          key: "clienteSituacionSAP",
          dataIndex: "clienteSituacionSAP",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.clienteSituacionSAP!.localeCompare(b.clienteSituacionSAP!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Situación Sentinel",
          key: "clienteSituacionSentinel",
          dataIndex: "clienteSituacionSentinel",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.clienteSituacionSentinel!.localeCompare(
              b.clienteSituacionSentinel!
            ),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Asesor ID",
          key: "creadorCodigo",
          dataIndex: "creadorCodigo",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.creadorCodigo!.localeCompare(b.creadorCodigo!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Pago",
          key: "solicitudCondicionPago",
          dataIndex: "solicitudCondicionPago",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudCondicionPago!.localeCompare(b.solicitudCondicionPago!),
          showSorterTooltip: false,
          className: "hide-column",
        },

        {
          title: "Fecha/Hora",
          key: "solicitudFechaCreacion",
          dataIndex: "solicitudFechaCreacion",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudFechaCreacion!.localeCompare(b.solicitudFechaCreacion!),
          showSorterTooltip: false,
          responsive: ["md"],
        },
        {
          title: "Garantía",
          key: "solicitudGarantia",
          dataIndex: "solicitudGarantia",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudGarantia!.localeCompare(b.solicitudGarantia!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "ID",
          key: "solicitudId",
          dataIndex: "solicitudId",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudId!.localeCompare(b.solicitudId!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Observaciones",
          key: "solicitudObservaciones",
          dataIndex: "solicitudObservaciones",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudObservaciones!.localeCompare(b.solicitudObservaciones!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Cotización PDF",
          key: "solicitudPDFCotizacion",
          dataIndex: "solicitudPDFCotizacion",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudPDFCotizacion!.localeCompare(b.solicitudPDFCotizacion!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Plazo",
          key: "solicitudPlazo",
          dataIndex: "solicitudPlazo",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudPlazo! - b.solicitudPlazo!,
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Porcentaje",
          key: "solicitudPorcentaje",
          dataIndex: "solicitudPorcentaje",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudPorcentaje! - b.solicitudPorcentaje!,
          showSorterTooltip: false,
          render: (value, _, __) => {
            return `${value} %`;
          },
          responsive: ["md"],
        },

        {
          title: "Cotización",
          key: "solicitudTotalCotizacion",
          dataIndex: "solicitudTotalCotizacion",
          align: "center",
          sorter: (a: Solicitud, b: Solicitud) =>
            a.solicitudTotalCotizacion! - b.solicitudTotalCotizacion!,
          showSorterTooltip: false,
          render: (value, _, __) => {
            return `S/${value}`;
          },
          className: "hide-column",
        },
      ]}
      dataSource={_listaSolicitudes?.map((_solicitud: any) => ({
        ..._solicitud,
      }))}
    />
  );
}
export default TablaSolicitudes;

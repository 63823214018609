import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { FormInstance } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Space } from "antd";
import { useState } from "react";
import Global from "../../global/global";

function AgregarInforme({
  _formInformeFinal,
  _subirInformeFinal,
  _setVisibleInformeFinal,
}: {
  _formInformeFinal: FormInstance;
  _subirInformeFinal: Function;
  _setVisibleInformeFinal: Function;
}) {
  const [_modal, _contextHolder] = Modal.useModal();
  const [_file, _setFile] = useState<any>(null);
  const [_fileName, _setFileName] = useState("Seleccionar archivo");

  const _onChangeFile = async (values: any) => {
    _setFileName(values.target.files[0].name);
    _setFile(values.target.files[0]);
  };

  return (
    <Fragment>
      <Form
        form={_formInformeFinal}
        name="form-informe-final"
        layout="vertical"
        autoComplete="off"
        onFinish={() => {
          _modal.confirm({
            title: Global.NOMBRE_EMPRESA,
            content:
              "¿Está seguro subir el informe final? Esta acción no se puede revertir",
            centered: true,
            okText: `Si, subir`,
            cancelText: "No, cancelar",
            onOk: () => {
              _subirInformeFinal(_file);
              _formInformeFinal.resetFields();
              _setVisibleInformeFinal(false);
            },
          });
        }}
      >
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="col-padding-detalle-solicitud"
          >
            <Form.Item
              label="Informe Final"
              name="informeFinalFirmado"
              rules={[
                {
                  required: true,
                  message: "¡Por favor sube el informe final firmado!",
                },
              ]}
            >
              <label className="custom-label">
                {_fileName}
                <Input
                  type="file"
                  hidden
                  onChange={_onChangeFile}
                  accept="application/pdf"
                />
              </label>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="col-padding-detalle-solicitud"
          >
            <Form.Item>
              <Space>
                <Button
                  onClick={() => {
                    _formInformeFinal.resetFields();
                    _setVisibleInformeFinal(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit">
                  Ok
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {_contextHolder}
    </Fragment>
  );
}

export default AgregarInforme;

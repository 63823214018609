import { Col } from "antd";
import { Form } from "antd";
import { FormInstance } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Row } from "antd";

function InformacionAsesor({ _formAsesor }: { _formAsesor: FormInstance }) {
  return (
    <Fragment>
      <Form
        form={_formAsesor}
        name="form-asesor"
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="Asesor (a)" name="creadorNombre">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item label="Sede" name="creadorSede">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}

export default InformacionAsesor;

/* eslint-disable no-restricted-globals */
import { APIResponse } from "../../model/apiresponse";
import { APIUsuario } from "../../api/api_usuario";
import { Breadcrumb } from "antd";
import { Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FormOutlined } from "@ant-design/icons";
import { Fragment } from "react";
import { Layout } from "antd";
import { Menu } from "antd";
import { Modal } from "antd";
import { OrderedListOutlined } from "@ant-design/icons";
import { Outlet } from "react-router-dom";
import { PoweroffOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import ErrorComponent from "../util/error.component";
import Global from "../../global/global";
import LoadComponent from "../util/load.component";
import logo from "../../asset/logo_blanco.svg";
import UsuarioContext from "../../provider/usuario-provider";

function DashboardPage({
  _isMobile,
  _routes,
}: {
  _isMobile: boolean;
  _routes: string[];
}) {
  const [_collapsed, _setCollapsed] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_path, _setPath] = useState("");
  const [_usuario, _setUsuario] = useState<Usuario>(new Usuario({}));
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { Header, Content, Footer, Sider } = Layout;
  const { Item } = Menu;
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    _apiUsuario
      ._getCurrenUser()
      .then(async (_user: any) => {
        if (_user) {
          let _usuario: Usuario = await _apiUsuario._getUser(_user.uid);
          _setUsuario(_usuario);
          let _data = location.pathname.split("/");
          if (_data && _data.length > 0) {
            let _path = _data[_data.length - 1];
            _setPath(_path);
          }
        } else {
          _navigate("/login");
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }, [_apiUsuario, _navigate]);

  const _onClickOptions = async (values: any) => {
    if (values["key"] === "1") {
      _logout();
    } else {
      _changePassword();
    }
  };

  const _logout = async () => {
    _modal.confirm({
      title: Global.NOMBRE_EMPRESA,
      content: "¿Está seguro de cerrar su sesión?",
      centered: true,
      okText: "Si, cerrar",
      cancelText: "No, cancelar",
      onOk: async () => {
        await _apiUsuario._logout(_usuario.id!);
        _navigate("/");
      },
    });
  };

  const _changePassword = async () => {
    _modal.confirm({
      title: Global.NOMBRE_EMPRESA,
      content: "¿Está seguro de cambiar su contraseña?",
      centered: true,
      okText: "Si",
      cancelText: "No",
      onOk: async () => {
        let _result: APIResponse = await _apiUsuario._changePassword(
          _usuario.correo!
        );
        if (_result.success) {
          _modal.success({
            title: Global.NOMBRE_EMPRESA,
            content: _result.message,
            centered: true,
          });
        } else {
          _modal.error({
            title: Global.NOMBRE_EMPRESA,
            content: _result.message,
            centered: true,
          });
        }
      },
    });
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  const _renderRoutes = (_routes: string[]) => {
    return (
      <Breadcrumb>
        {_routes.map((_route: string, _index: number) => {
          return (
            <Breadcrumb.Item
              key={`${_route}`}
              onClick={
                _route === "Reporte"
                  ? () => {
                      _navigate(`/${_route.toLowerCase()}`);
                    }
                  : () => {}
              }
            >
              {_route}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  const _renderFooter = () => {
    return <Footer id="footer">3AAMSEQ SA | SRSS ©2022 Creado por TI</Footer>;
  };

  const _renderOpciones = () => {
    return (
      <DropdownButton
        className="button-logout"
        overlay={
          <Menu onClick={_onClickOptions}>
            <Item key="1" icon={<PoweroffOutlined />}>
              Cerrar sesi&oacute;n
            </Item>
            <Item key="2" icon={<SettingOutlined />}>
              Cambiar contraseña
            </Item>
          </Menu>
        }
        placement="bottomCenter"
        icon={<DownOutlined />}
        trigger={["click"]}
      >
        {_usuario.nombres}
      </DropdownButton>
    );
  };

  const _renderMenu = (
    _usuario: Usuario,
    _isMobile: boolean,
    _path: string
  ) => {
    return (
      <Menu
        theme="dark"
        mode={_isMobile ? "horizontal" : "inline"}
        selectedKeys={[_path]}
      >
        {_usuario.perfil === Global.PERFILES[5] ||
        _usuario.perfil === Global.PERFILES[0] ? (
          <Fragment>
            <Item
              key="reporte"
              icon={<OrderedListOutlined />}
              onClick={() => {
                _navigate("/reporte");
              }}
            >
              Lista de solicitudes
            </Item>
            <Item
              key="registro"
              icon={<FormOutlined />}
              onClick={() => {
                _navigate("/registro");
              }}
            >
              Nueva solicitud
            </Item>
          </Fragment>
        ) : (
          <Fragment>
            <Item
              key="reporte"
              icon={<OrderedListOutlined />}
              onClick={() => {
                _navigate("/reporte");
              }}
            >
              Lista de solicitudes
            </Item>
          </Fragment>
        )}
      </Menu>
    );
  };

  const _renderSede = (_usuario: Usuario) => {
    return (
      <div id="sede">
        <p>{_usuario.sede}</p>
      </div>
    );
  };

  const _renderLogo = () => {
    return <img id="logo" src={logo} alt="3AAMSEQ" title="3AAMSEQ" />;
  };

  const _renderContent = () => {
    return <Outlet />;
  };

  return _usuario.perfil ? (
    <Fragment>
      {_isMobile ? (
        <Layout>
          <Header style={{ position: "fixed", width: "100%", zIndex: 1 }}>
            <div className="logo">{_renderLogo()}</div>
            {_renderMenu(_usuario, _isMobile, _path)}
          </Header>
          <Content id="content">
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-center"
              >
                {_renderRoutes(_routes)}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-center"
              >
                {_renderSede(_usuario)}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
                className="col-center"
              >
                {_renderOpciones()}
              </Col>
            </Row>
            <hr />
            {/* Content */}
            {_renderContent()}
          </Content>
          {/* Footer */}
          {_renderFooter()}
        </Layout>
      ) : (
        <Layout id="layout-main">
          <Sider
            collapsible
            collapsed={_collapsed}
            onCollapse={(_collapsed) => {
              _setCollapsed(_collapsed);
            }}
          >
            {_renderLogo()}
            {_renderSede(_usuario)}
            <hr />
            {_renderMenu(_usuario, _isMobile, _path)}
          </Sider>
          <Layout>
            <Header id="header">{_renderOpciones()}</Header>
            <Content id="content">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  {_renderRoutes(_routes)}
                  <hr />
                </Col>
              </Row>
              {/* Content */}
              {_renderContent()}
            </Content>
            {/* Footer */}
            {_renderFooter()}
          </Layout>
        </Layout>
      )}
      {_contextHolder}
    </Fragment>
  ) : (
    <LoadComponent />
  );
}

export default DashboardPage;

/* eslint-disable jsx-a11y/iframe-has-title */
import { Col } from "antd";
import { Fragment } from "react";
import { Row } from "antd";

function LeerPDF({ _url }: { _url: string }) {
  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <iframe src={_url} height="500px" width="100%"></iframe>
        </Col>
      </Row>
    </Fragment>
  );
}

export default LeerPDF;

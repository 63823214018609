// import notfound from "../../asset/notfound.svg";
import { HomeOutlined } from "@ant-design/icons";

function NotFoundComponent() {
  return (
    <div id="notfound-component">
      {/* <img src={notfound} alt="3A AMSEQ" title="PÁGINA NO ENCONTRADA" /> */}
      <h1 id="title">404</h1>
      <h1 id="body">No se econtr&oacute; el contenido solicitado</h1>
      <h2>Se lo llevaron los extraterrestres</h2>
      <div id="div-icon-notfound">
        <a href="/" rel="noreferrer">
          <HomeOutlined id="icon-notfound" />
        </a>
      </div>
    </div>
  );
}

export default NotFoundComponent;

export interface UsuarioDataI {
  id?: string;
  nombres?: string;
  correo?: string;
  perfil?: string;
  firma?: string | null;
  sede?: string;
}

export class Usuario implements UsuarioDataI {
  id?: string;
  nombres?: string;
  correo?: string;
  perfil?: string;
  firma?: string | null;
  sede?: string;

  constructor(data: UsuarioDataI) {
    this.id = data.id;
    this.nombres = data.nombres;
    this.correo = data.correo;
    this.perfil = data.perfil;
    this.firma = data.firma;
    this.sede = data.sede;
  }
}

import { APIPeru } from "../../api/api_peru";
import { APIResponse } from "../../model/apiresponse";
import { APISolicitud } from "../../api/api_solicitud";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment, useCallback } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Select } from "antd";
import { Solicitud } from "../../model/solicitud";
import { Spin } from "antd";
import { Typography } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import ErrorComponent from "../util/error.component";
import Global from "../../global/global";
import LoadComponent from "../util/load.component";
import PeruContext from "../../provider/peru-provider";
import SolicitudContext from "../../provider/solicitud-provider";
import { APIUsuario } from "../../api/api_usuario";
import UsuarioContext from "../../provider/usuario-provider";
import { useNavigate } from "react-router-dom";

function RegistroPage({ _setRoutes }: { _setRoutes: Function }) {
  const { Title } = Typography;
  const { Option } = Select;
  const _apiSolicitud = useContext<APISolicitud>(SolicitudContext);
  const _apiPeru = useContext<APIPeru>(PeruContext);
  const [_usuario, _setUsuario] = useState<Usuario>(new Usuario({}));
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const [_mounted, _setMounted] = useState<any>(null);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_load, _setLoad] = useState<boolean>(false);
  const [_formKey] = Form.useForm();
  const [_fileName, _setFileName] = useState("Seleccionar archivo");
  const [_file, _setFile] = useState<any>(null);
  const [_especificar, _setEspecificar] = useState<boolean>(true);
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    _apiUsuario
      ._getCurrenUser()
      .then(async (_user: any) => {
        if (_user) {
          let _usuario: Usuario = await _apiUsuario._getUser(_user.uid);
          _setUsuario(_usuario);
        } else {
          _navigate("/login");
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }, [_apiUsuario, _navigate]);

  const _onSearchCliente = async (_documento: string) => {
    if (_documento.length >= 8) {
      _setLoad(true);
      let _result: APIResponse = await _apiPeru._searchCliente(_documento);
      if (_result.success) {
        _formKey.setFieldsValue({
          clienteNombre: _result.data,
        });
      } else {
        _formKey.setFieldsValue({
          clienteNombre: "No se encontró el cliente",
        });
      }
      _setLoad(false);
    }
  };

  const _onChangeFile = async (values: any) => {
    _setFileName(values.target.files[0].name);
    _setFile(values.target.files[0]);
  };

  const _onChangeGarantia = (value: string) => {
    if (value === Global.TIPO_GARANTIA[3]) {
      _setEspecificar(false);
    } else {
      _setEspecificar(true);
    }
  };

  const _getPorcentaje = (
    _clienteLineaCredito: number,
    _clienteLineaCreditoUsada: number,
    _solicitudTotalCotizacion: number
  ) => {
    let _adicional: number = 0;
    let _porcentaje: number = 0;
    _adicional =
      parseFloat(_solicitudTotalCotizacion.toString()) +
      (_clienteLineaCreditoUsada - _clienteLineaCredito);
    _porcentaje = (_adicional * 100) / _clienteLineaCredito;
    return parseFloat(_porcentaje.toFixed(2));
  };

  const _getTipo = (_porcentaje: number) => {
    let _tipo: string = "";
    if (_porcentaje > 0 && _porcentaje <= 20) {
      _tipo = Global.TIPOS[0];
    } else if (_porcentaje > 20 && _porcentaje <= 40) {
      _tipo = Global.TIPOS[1];
    } else {
      _tipo = Global.TIPOS[2];
    }
    return _tipo;
  };

  const _registrarSolicitud = async (_solicitud: Solicitud) => {
    let _result: APIResponse = await _apiSolicitud._createSolicitud(
      _solicitud,
      _usuario
    );
    if (_result.success) {
      _modal.success({
        title: Global.NOMBRE_EMPRESA,
        content: _result.message,
        centered: true,
        onOk: () => {
          _formKey.resetFields();
        },
      });
    } else {
      _modal.error({
        title: Global.NOMBRE_EMPRESA,
        content: _result.message,
        centered: true,
      });
    }
    _setLoad(false);
  };

  const _onFinish = async () => {
    _modal.confirm({
      title: Global.NOMBRE_EMPRESA,
      content: "¿Está seguro de registrar esta solicitud?",
      centered: true,
      onOk: async () => {
        if (
          await _apiSolicitud._verificarSolicitudCliente(
            _usuario.id!,
            _formKey.getFieldValue("clienteCodigo")
          )
        ) {
          _modal.error({
            title: Global.NOMBRE_EMPRESA,
            content:
              "Ya tienes una solcitud en proceso o pendiente para este cliente",
            centered: true,
          });
        } else {
          let _clienteCodigo: string = _formKey.getFieldValue("clienteCodigo");
          let _clienteNombre: string = _formKey.getFieldValue("clienteNombre");
          let _clienteLineaCredito: number = _formKey.getFieldValue(
            "clienteLineaCredito"
          );
          let _clienteLineaCreditoUsada: number = _formKey.getFieldValue(
            "clienteLineaCreditoUsada"
          );
          let _solicitudTotalCotizacion: number = _formKey.getFieldValue(
            "solicitudTotalCotizacion"
          );
          let _solicitudPlazo: number =
            _formKey.getFieldValue("solicitudPlazo");
          let _solicitudCondicionPago: string = _formKey.getFieldValue(
            "solicitudCondicionPago"
          );
          let _solicitudGarantia: string = _formKey.getFieldValue(
            _especificar ? "solicitudGarantia" : "solicitudGarantiaEspecífica"
          );
          let _solicitudPorcentaje: number = _getPorcentaje(
            _clienteLineaCredito,
            _clienteLineaCreditoUsada,
            _solicitudTotalCotizacion
          );
          if (_solicitudPorcentaje <= 0) {
            _modal.error({
              title: Global.NOMBRE_EMPRESA,
              content:
                "La línea de crédito usada más el monto total de la cotización deben ser mayor a la línea de crédito asignada",
              centered: true,
            });
          } else {
            _setLoad(true);
            let _formData: FormData = new FormData();
            _formData.append("file", _file);
            let _xhttp: XMLHttpRequest = new XMLHttpRequest();
            _xhttp.open("POST", Global.URL_SUBIR_COTIZACION, true);
            _xhttp.onreadystatechange = () => {
              if (_xhttp.readyState === 4 && _xhttp.status === 200) {
                let _response: any = JSON.parse(_xhttp.responseText);
                if (_response.success === 1) {
                  let _solicitudPDFCotizacion: string = `${Global.URL_COTIZACION}${_response.message}`;
                  let _date: Date = new Date();
                  let _solicitudFechaCreacion: string = _date.toLocaleString(
                    "es-ES",
                    Global.FORMAT_DATE
                  );
                  let _solicitud: Solicitud = new Solicitud({
                    clienteCodigo: _clienteCodigo,
                    clienteLineaCredito: _clienteLineaCredito,
                    clienteLineaCreditoUsada: _clienteLineaCreditoUsada,
                    clienteNombre: _clienteNombre,
                    clienteSituacionSAP: null,
                    clienteSituacionSentinel: null,
                    creadorCodigo: _usuario.id,
                    creadorNombre: _usuario.nombres,
                    creadorSede: _usuario.sede,
                    solicitudCondicionPago: _solicitudCondicionPago,
                    solicitudEstado: Global.ESTADOS[0],
                    solicitudFechaCreacion: _solicitudFechaCreacion,
                    solicitudGarantia: _solicitudGarantia,
                    solicitudId: null,
                    solicitudObservaciones: null,
                    solicitudPDFCotizacion: _solicitudPDFCotizacion,
                    solicitudPlazo: _solicitudPlazo,
                    solicitudPorcentaje: _solicitudPorcentaje,
                    solicitudTipo: _getTipo(_solicitudPorcentaje),
                    solicitudTotalCotizacion: _solicitudTotalCotizacion,
                    solicitudInformeFinal: null,
                  });
                  _registrarSolicitud(_solicitud);
                } else {
                  _modal.error({
                    title: Global.NOMBRE_EMPRESA,
                    content:
                      "Ocurrió un error al cargar el archivo, inténtalo de nuevo",
                    centered: true,
                  });
                  _setLoad(false);
                }
              }
            };
            _xhttp.send(_formData);
          }
        }
      },
    });
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    _setRoutes(["Inicio", "Registro"]);
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser, _setRoutes]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return _usuario.perfil ? (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Title level={3} className="titulo">
            Registrar solicitud
          </Title>
          <Title level={5} className="subtitulo">
            Completa los campos solicitados para registrar una nueva solicitud
          </Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Spin spinning={_load}>
            <Form
              form={_formKey}
              name="form-registro"
              layout="vertical"
              onFinish={_onFinish}
              autoComplete="off"
            >
              <Row>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label="DNI/RUC Cliente"
                    name="clienteCodigo"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa el DNI/RUC cliente!",
                        max: 11,
                        min: 8,
                      },
                    ]}
                  >
                    <Input.Search
                      allowClear
                      onSearch={_onSearchCliente}
                      type="number"
                      minLength={8}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label="Nombre/Raz&oacute;n Social Cliente"
                    name="clienteNombre"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa Nombre / Razón Social!",
                      },
                    ]}
                  >
                    <Input allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    label="L&iacute;nea de cr&eacute;dito (S/)"
                    name="clienteLineaCredito"
                    rules={[
                      {
                        required: true,
                        message:
                          "¡Por favor ingresa la línea de crédito del cliente!",
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      type="number"
                      pattern="[0-9]*"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label="L&iacute;nea de cr&eacute;dito usada (S/)"
                    name="clienteLineaCreditoUsada"
                    rules={[
                      {
                        required: true,
                        message:
                          "¡Por favor ingresa la línea de crédito usada del cliente!",
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      type="number"
                      pattern="[0-9]*"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label="Monto de la cotizaci&oacute;n (S/)"
                    name="solicitudTotalCotizacion"
                    rules={[
                      {
                        required: true,
                        message:
                          "¡Por favor ingresa el monto de la cotización!",
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      type="number"
                      pattern="[0-9]*"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label="Cotización (PDF)"
                    name="solicitudPDFCotizacion"
                    rules={[
                      {
                        required: true,
                        message:
                          "¡Por favor sube el archivo de la cotización de tu cliente!",
                      },
                    ]}
                  >
                    <label className="custom-label">
                      {_fileName}
                      <Input
                        type="file"
                        hidden
                        onChange={_onChangeFile}
                        accept="application/pdf"
                      />
                    </label>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                  <Form.Item
                    label="Plazo (días)"
                    name="solicitudPlazo"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa el plazo en días!",
                      },
                    ]}
                  >
                    <Input
                      allowClear
                      type="number"
                      pattern="[0-9]*"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={_especificar ? 12 : 8}
                  xl={_especificar ? 12 : 8}
                  xxl={_especificar ? 12 : 8}
                >
                  <Form.Item
                    label="Condición de pago"
                    name="solicitudCondicionPago"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor selecciona una condición de pago!",
                      },
                    ]}
                  >
                    <Select placeholder="Elija una opción">
                      {Global.CONDICION_PAGO.map(
                        (condicion: String, index: number) => (
                          <Option
                            key={`${condicion}_${index}`}
                            value={condicion}
                          >
                            {condicion}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={_especificar ? 12 : 8}
                  xl={_especificar ? 12 : 8}
                  xxl={_especificar ? 12 : 8}
                >
                  <Form.Item
                    label="Garantía"
                    name="solicitudGarantia"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor selecciona la garantía!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Elija una opción"
                      onChange={_onChangeGarantia}
                    >
                      {Global.TIPO_GARANTIA.map(
                        (garantia: String, index: number) => (
                          <Option key={`${garantia}_${index}`} value={garantia}>
                            {garantia}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                {!_especificar ? (
                  <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      label="Especificar garant&iacute;a"
                      name="solicitudGarantiaEspecífica"
                      rules={
                        _especificar
                          ? []
                          : [
                              {
                                required: true,
                                message:
                                  "¡Por favor especifica la garantía de tu cliente!",
                              },
                            ]
                      }
                    >
                      <Input allowClear disabled={_especificar} />
                    </Form.Item>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Registrar solicitud
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
      {_contextHolder}
    </Fragment>
  ) : (
    <LoadComponent />
  );
}

export default RegistroPage;

import { _getToken } from "../../firebaseConfig";
import { _onMessageListener } from "../../firebaseConfig";
import { BrowserRouter } from "react-router-dom";
import { Fragment } from "react";
import { Modal } from "antd";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import DashboardPage from "./dashboard.page";
import DetalleSolicitudPage from "./detalle.page";
import ErrorComponent from "../util/error.component";
import LoadComponent from "../util/load.component";
import LoginPage from "./login.page";
import NotFoundComponent from "../util/notfound.component";
import RegistroPage from "./registro.page";
import ReportePage from "./reporte.page";
import SingupPage from "./singup.page";

function AppPage() {
  const [_isMobile, _setIsMobile] = useState<boolean>(false);
  const [_mounted, _setMounted] = useState<any>(null);
  const [_routes, _setRoutes] = useState<string[]>(["Inicio"]);
  const [_audio] = useState<any>(
    typeof Audio !== "undefined" &&
      new Audio("https://3aamseq.com.pe/srss_cotizaciones/notification.wav")
  );

  useEffect(() => {
    _setMounted(true);
    _getToken();
    _onMessageListener()
      .then((payload) => {
        _audio.play();
        Modal.info({
          title: payload.notification.title,
          content: payload.notification.body,
          centered: true,
        });
      })
      .catch((_error) => {
        console.log(_error);
      });
    _setIsMobile(_detectMobile());
    return () => {
      _setMounted(false);
    };
  }, [_audio]);

  const _detectMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  };

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={<DashboardPage _isMobile={_isMobile} _routes={_routes} />}
          />
          <Route
            path="/*"
            element={<DashboardPage _isMobile={_isMobile} _routes={_routes} />}
          >
            <Route index element={<ReportePage _setRoutes={_setRoutes} />} />
            <Route
              path="detalle/:_solicitudId"
              element={
                <DetalleSolicitudPage
                  _isMobile={_isMobile}
                  _setRoutes={_setRoutes}
                />
              }
            />
            <Route
              path="reporte"
              element={<ReportePage _setRoutes={_setRoutes} />}
            />
            <Route
              path="registro"
              element={<RegistroPage _setRoutes={_setRoutes} />}
            />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="singup" element={<SingupPage />} />
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default AppPage;

import { Document } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Text } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
import { useEffect } from "react";
import { useState } from "react";
import Global from "../../global/global";
import { Solicitud } from "../../model/solicitud";
import { Voto } from "../../model/voto";

function GenerarPDF({
  _solicitud,
  _votos,
}: {
  _solicitud: Solicitud;
  _votos: Voto[];
}) {
  const [_lineaDisponible, _setLineaDisponible] = useState<number>(0);
  const [_sobregiro, _setSobregiro] = useState<number>(0);
  const stylesPDF = StyleSheet.create({
    center: {
      textAlign: "center",
    },
    left: {
      textAlign: "left",
    },
    right: {
      textAlign: "right",
    },
    borderTop: {
      borderTopColor: "#000000",
      borderTopStyle: "solid",
      borderTopWidth: 0.5,
    },
    borderBottom: {
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
      borderBottomWidth: 0.5,
    },
    borderLeft: {
      borderLeftColor: "#000000",
      borderLeftStyle: "solid",
      borderLeftWidth: 0.5,
    },
    borderRight: {
      borderRightColor: "#000000",
      borderRightStyle: "solid",
      borderRightWidth: 0.5,
    },
    width25: {
      width: "25%",
    },
    width50: {
      width: "50%",
    },
    width75: {
      width: "75%",
    },
    width100: {
      width: "100%",
    },
    page: {
      backgroundColor: "#ffffff",
    },
    header: {
      padding: 10,
      flexGrow: 1,
    },
    logo: { marginLeft: 30, height: 40, width: 225 },
    firma: { height: 20, width: 110 },
    body: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    titulo: {
      marginTop: 20,
      textDecoration: "underline",
      textAlign: "center",
      fontSize: 12.5,
    },
    motivo: { fontSize: 12.5, margin: "20px 50px" },
    descripcion: { fontSize: 12.5, margin: "20px 50px", textAlign: "justify" },
    sede: { fontSize: 12.5, margin: "5px 50px", fontWeight: "extrabold" },
    fecha: { fontSize: 12.5, margin: "5px 50px", fontWeight: "extrabold" },
    cliente: { fontSize: 12.5, margin: "5px 50px", fontWeight: "extrabold" },
    ruc: { fontSize: 12.5, margin: "5px 50px", fontWeight: "extrabold" },
    observacionesTitulo: {
      fontSize: 12.5,
      margin: "5px 50px",
      fontWeight: "extrabold",
      textDecoration: "underline",
    },
    observacionesCuerpo: {
      fontSize: 12.5,
      margin: "5px 50px",
      fontWeight: "extrabold",
    },
    resultado: {
      fontSize: 12.5,
      margin: "5px 0px 5px 50px",
      fontWeight: "extrabold",
    },
    estado: {
      fontSize: 12.5,
      margin: "5px 0px",
      fontWeight: "extrabold",
    },
    tabla: {
      margin: "10px 50px",
      fontSize: 10,
      textAlign: "right",
    },
    row: {
      flexDirection: "row",
    },
    cell: {
      padding: 4,
      width: "50%",
    },
  });

  useEffect(() => {
    if (_solicitud) {
      let _lineaCredito = _solicitud.clienteLineaCredito!;
      let _lineaCreditoUsada = _solicitud.clienteLineaCreditoUsada!;
      let _cotizacion = _solicitud.solicitudTotalCotizacion!;
      let _lineaDisponible = _lineaCredito - _lineaCreditoUsada;
      _setLineaDisponible(_lineaDisponible);
      let _sobregiro = _cotizacion - _lineaDisponible;
      _setSobregiro(_sobregiro);
    }
  }, [_solicitud]);

  return (
    <Document title="Informe Final" author={Global.NOMBRE_EMPRESA}>
      <Page size="A4" style={stylesPDF.page}>
        <View style={stylesPDF.body}>
          <Image
            source="https://www.3aamseq.com.pe/srss_cotizaciones/logo_azul.jpg"
            style={stylesPDF.logo}
          />
          <Text style={stylesPDF.titulo}>
            {`EXTENSION DE LINEA DE MANERA PUNTUAL - ${Global.NOMBRE_EMPRESA}`}
          </Text>
          <Text style={stylesPDF.motivo}>Motivo: Venta puntual a realizar</Text>
          <Text style={stylesPDF.descripcion}>
            {`El cliente tiene una orden de compra por S/${parseFloat(
              _solicitud.solicitudTotalCotizacion!.toString() ?? ""
            ).toFixed(
              2
            )}, de los cuales desea se le brinde la facilidad de poder atenderle el importe al crédito con plazo a ${
              _solicitud.solicitudPlazo
            } días.`}
          </Text>
          {/* Primera tabla */}
          <View style={stylesPDF.tabla}>
            <View style={stylesPDF.row}>
              <Text style={[stylesPDF.cell, stylesPDF.width50]}></Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderTop,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`LÍNEA VIGENTE:`}
              </Text>

              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderTop,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`S/${parseFloat(
                  _solicitud.clienteLineaCredito!.toString() ?? ""
                ).toFixed(2)}`}
              </Text>
            </View>
            <View style={stylesPDF.row}>
              <Text style={[stylesPDF.cell, stylesPDF.width50]}></Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`PLAZO:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`${_solicitud.solicitudPlazo} días`}
              </Text>
            </View>
          </View>
          <Text
            style={stylesPDF.sede}
          >{`SEDE: ${_solicitud.creadorSede}`}</Text>
          <Text style={stylesPDF.fecha}>
            {`FECHA: ${_solicitud.solicitudFechaCreacion}`}
          </Text>
          <Text style={stylesPDF.cliente}>
            {`CLIENTE: ${_solicitud.clienteNombre}`}
          </Text>
          <Text
            style={stylesPDF.ruc}
          >{`RUC/DNI: ${_solicitud.clienteCodigo}`}</Text>
          {/* Segunda tabla */}
          <View style={stylesPDF.tabla}>
            <View style={stylesPDF.row}>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.center,
                  stylesPDF.width50,
                  stylesPDF.borderTop,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`LÍNEA DISPONIBLE`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.center,
                  stylesPDF.width50,
                  stylesPDF.borderTop,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`SOBREGIRO APROBADO`}
              </Text>
            </View>
            <View style={stylesPDF.row}>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`MONTO:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`S/${
                  _lineaDisponible <= 0
                    ? 0.0
                    : parseFloat(_lineaDisponible!.toString() ?? "").toFixed(2)
                }`}
              </Text>

              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`MONTO TOTAL:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`S/${parseFloat(_sobregiro!.toString() ?? "").toFixed(2)}`}
              </Text>
            </View>
            <View style={stylesPDF.row}>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`PLAZO:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`${_solicitud.solicitudPlazo} días`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`PLAZO:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`${_solicitud.solicitudPlazo} días`}
              </Text>
            </View>
            <View style={stylesPDF.row}>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.center,
                  stylesPDF.width50,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`SOBREGIRO`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`COND. PAGO`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {_solicitud.solicitudCondicionPago}
              </Text>
            </View>
            <View style={stylesPDF.row}>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`MONTO:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`S/${parseFloat(_sobregiro!.toString() ?? "").toFixed(2)} \n(${
                  _solicitud.solicitudPorcentaje
                } % de su línea)`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`GARANTÍA:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {_solicitud.solicitudGarantia}
              </Text>
            </View>
            <View style={stylesPDF.row}>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`PLAZO:`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.width25,
                  stylesPDF.center,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                ]}
              >
                {`${_solicitud.solicitudPlazo} días`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.center,
                  stylesPDF.width50,
                  stylesPDF.borderBottom,
                  stylesPDF.borderRight,
                  stylesPDF.borderLeft,
                ]}
              >
                {`Tiene utilizado en el sistema: S/${parseFloat(
                  _solicitud.clienteLineaCreditoUsada!.toString() ?? ""
                ).toFixed(2)}`}
              </Text>
            </View>
          </View>
          {/* Tercera tabla */}
          <View style={stylesPDF.tabla}>
            <View style={stylesPDF.row}>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width75,
                  stylesPDF.borderBottom,
                ]}
              >
                {`PARTICIPANTES`}
              </Text>
              <Text
                style={[
                  stylesPDF.cell,
                  stylesPDF.left,
                  stylesPDF.width25,
                  stylesPDF.borderBottom,
                ]}
              >
                {`FIRMAS`}
              </Text>
            </View>
            {/* 
            AQUI VA LA LISTA DE VOTANTES Y SU FIRMA
            */}
            {_votos.map((_voto: Voto, _index: number) => {
              return (
                <View style={stylesPDF.row} key={_voto.codigo}>
                  <Text
                    style={[
                      stylesPDF.cell,
                      stylesPDF.left,
                      stylesPDF.width75,
                      stylesPDF.borderBottom,
                      stylesPDF.borderRight,
                      stylesPDF.borderLeft,
                    ]}
                  >
                    {_voto.usuarioNombres}
                  </Text>
                  <Text
                    style={[
                      stylesPDF.cell,
                      stylesPDF.center,
                      stylesPDF.width25,
                      stylesPDF.borderBottom,
                      stylesPDF.borderRight,
                    ]}
                  >
                    {_voto.usuarioFirma ? (
                      <Image
                        source={_voto.usuarioFirma}
                        style={stylesPDF.firma}
                      />
                    ) : (
                      ""
                    )}
                  </Text>
                </View>
              );
            })}
          </View>
          <Text style={stylesPDF.observacionesTitulo}>{`OBSERVACIONES:`} </Text>
          <Text style={stylesPDF.observacionesCuerpo}>
            {_solicitud.solicitudObservaciones}
          </Text>
          <View style={stylesPDF.row}>
            <Text style={stylesPDF.resultado}>{`RESOLUCIÓN DE COMITÉ:`} </Text>
            <Text style={stylesPDF.estado}>{_solicitud.solicitudEstado}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default GenerarPDF;

import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { FormInstance } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Space } from "antd";
import Global from "../../global/global";

function AgregarObservaciones({
  _formObservaciones,
  _guardarObservaciones,
  _setVisibleObservaciones,
}: {
  _formObservaciones: FormInstance;
  _guardarObservaciones: Function;
  _setVisibleObservaciones: Function;
}) {
  const [_modal, _contextHolder] = Modal.useModal();

  return (
    <Fragment>
      <Form
        form={_formObservaciones}
        name="form-observaciones"
        layout="vertical"
        autoComplete="off"
        onFinish={() => {
          _modal.confirm({
            title: Global.NOMBRE_EMPRESA,
            content:
              "¿Está seguro de guardar las observaciones? Esta acción no se puede revertir",
            centered: true,
            okText: `Si, guardar`,
            cancelText: "No, cancelar",
            onOk: () => {
              _guardarObservaciones(
                _formObservaciones.getFieldValue("observaciones")
              );
              _formObservaciones.resetFields();
              _setVisibleObservaciones(false);
            },
          });
        }}
      >
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="col-padding-detalle-solicitud"
          >
            <Form.Item
              label="Observaciones"
              name="observaciones"
              rules={[
                {
                  required: true,
                  message: "¡Por favor ingrese su observaciones!",
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="col-padding-detalle-solicitud"
          >
            <Form.Item>
              <Space>
                <Button
                  onClick={() => {
                    _formObservaciones.resetFields();
                    _setVisibleObservaciones(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button type="primary" htmlType="submit">
                  Ok
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {_contextHolder}
    </Fragment>
  );
}

export default AgregarObservaciones;

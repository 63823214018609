import { APIResponse } from "../../model/apiresponse";
import { APIUsuario } from "../../api/api_usuario";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Select } from "antd";
import { Spin } from "antd";
import { Typography } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ErrorComponent from "../util/error.component";
import Global from "../../global/global";
import LoadComponent from "../util/load.component";
import logo from "../../asset/logo_azul.svg";
import UsuarioContext from "../../provider/usuario-provider";

function SingupPage() {
  const [_esAsesor, _setEsAsesor] = useState<boolean>(true);
  const [_file, _setFile] = useState<any>(null);
  const [_fileName, _setFileName] = useState("Seleccionar archivo");
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  const { Option } = Select;
  const { Title } = Typography;
  const [_formKey] = Form.useForm();

  const _singup = async () => {
    _setLoad(true);
    let _urlFirma: string = "";
    let _formData: FormData = new FormData();
    _formData.append("file", _file);
    let _xhttp: XMLHttpRequest = new XMLHttpRequest();
    _xhttp.open("POST", Global.URL_SUBIR_FIRMA, true);
    if (_file === null) {
      _createUser();
    } else {
      _xhttp.onreadystatechange = () => {
        if (_xhttp.readyState === 4 && _xhttp.status === 200) {
          let _response = JSON.parse(_xhttp.responseText);
          if (_response.success === 1) {
            _urlFirma = `${Global.URL_FIRMAS}${_response.message}`;
            _formKey.setFieldsValue({ firma: _urlFirma });
            _createUser();
          } else {
            _modal.error({
              title: Global.NOMBRE_EMPRESA,
              content:
                "Ocurrió un error al cargar la firma, inténtalo más tarde",
              centered: true,
            });
            _setLoad(false);
          }
        }
      };
      _xhttp.send(_formData);
    }
  };

  const _createUser = async () => {
    await _apiUsuario
      ._createUser(
        _formKey.getFieldValue("correo"),
        _formKey.getFieldValue("password"),
        _formKey.getFieldValue("nombres"),
        _formKey.getFieldValue("perfil"),
        _formKey.getFieldValue("sede"),
        _formKey.getFieldValue("firma")
      )
      .then((_response: APIResponse) => {
        _setLoad(false);
        if (_response.success) {
          _modal.success({
            title: Global.NOMBRE_EMPRESA,
            content: _response.message,
            centered: true,
          });
        } else {
          _modal.error({
            title: Global.NOMBRE_EMPRESA,
            content: _response.message,
            centered: true,
          });
        }
      })
      .catch((error) => {
        _setLoad(false);
        _modal.error({
          title: Global.NOMBRE_EMPRESA,
          content: error,
          centered: true,
        });
      });
  };

  const _onChangePerfil = (value: string) => {
    if (value === Global.PERFILES[0] || value === Global.PERFILES[5]) {
      _setEsAsesor(true);
    } else {
      _setEsAsesor(false);
    }
  };

  const _onChangeFile = async (values: any) => {
    _setFileName(values.target.files[0].name);
    _setFile(values.target.files[0]);
  };

  useEffect(() => {
    _setMounted(true);
    return () => {
      _setMounted(false);
    };
  }, []);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Form
            name="form-singup"
            layout="vertical"
            onFinish={_singup}
            autoComplete="off"
            form={_formKey}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <img
                  src={logo}
                  alt="3AAMSEQ"
                  title="3AAMSEQ"
                  width={300}
                  loading="lazy"
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Title level={4}>
                  SISTEMA DE REGISTRO DE SOLICITUDES DE SOBREGIRO
                </Title>
                <Title level={4}>Reg&iacute;strate</Title>
                <Title level={5}>
                  Ingresa los datos solicitados para registrarte
                </Title>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label="Perfil"
                  name="perfil"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor selecciona tu perfil!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Elija una opción"
                    onChange={_onChangePerfil}
                  >
                    {Global.PERFILES.map((perfil: String, index: number) => (
                      <Option key={`${perfil}_${index}`} value={perfil}>
                        {perfil}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label="Nombres"
                  name="nombres"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tus nombres completos!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label="Correo"
                  name="correo"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tu correo!",
                      type: "email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor ingresa tu contraseña!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label="Sede"
                  name="sede"
                  rules={[
                    {
                      required: true,
                      message: "¡Por favor selecciona tu sede",
                    },
                  ]}
                >
                  <Select placeholder="Elija una opción">
                    {Global.SEDES.map((sede: String, index: number) => (
                      <Option key={`${sede}_${index}`} value={sede}>
                        {sede}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label="Firma"
                  name="firma"
                  hidden={_esAsesor}
                  rules={
                    _esAsesor
                      ? []
                      : [
                          {
                            required: true,
                            message:
                              "¡Por favor selecciona una imagen con tu firma!",
                          },
                        ]
                  }
                >
                  <label className="custom-label">
                    {_fileName}
                    <Input
                      type="file"
                      hidden
                      onChange={_onChangeFile}
                      accept="image/*"
                    />
                  </label>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item>
                  {_load ? (
                    <Spin />
                  ) : (
                    <Button type="primary" htmlType="submit">
                      Crear usuario
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default SingupPage;

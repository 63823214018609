import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAQWs1eJHljSy8Odtrolue1oQ2hQcsQFI0",
  authDomain: "ssv2-7584e.firebaseapp.com",
  projectId: "ssv2-7584e",
  storageBucket: "ssv2-7584e.appspot.com",
  messagingSenderId: "1060467750232",
  appId: "1:1060467750232:web:f71dfb1e8ce35653c1e12d",
  measurementId: "G-WRYLQL87J9",
};

firebase.initializeApp(firebaseConfig);
const _messaging = firebase.messaging();

export const _getToken = async () => {
  let _token = "";
  await _messaging
    .getToken({
      vapidKey:
        "BMfT0gCPFw6FSWsW9PV86timQ_v0PfPgGwOfRy6no7TidiMx8pD1fmDj1CuHAd-8GnPI3TCHh4DaYylJgNPETU8",
    })
    .then((_currentToken) => {
      if (_currentToken) {
        _token = _currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((_error) => {
      console.log("An error occurred while retrieving token. ", _error);
    });
  return _token;
};

export const _onMessageListener = () =>
  new Promise((resolve) => {
    _messaging.onMessage((_payload) => {
      resolve(_payload);
    });
  });

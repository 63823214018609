import { APIResponse } from "../model/apiresponse";
import { AxiosResponse } from "axios";
import axios from "axios";
import Global from "../global/global";

export class APIPeru {
  async _searchCliente(documento: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    let _tipo: string = "";
    let _cliente: string = "";
    if (documento.length === 8) {
      _tipo = "dni";
    } else {
      _tipo = "ruc";
    }

    await axios
      .get(
        `https://dniruc.apisperu.com/api/v1/${_tipo}/${documento}?token=${Global.API_PERU}`
      )
      .then((_data: AxiosResponse) => {
        if (documento.length === 8) {
          _cliente = `${_data.data["apellidoPaterno"]} ${_data.data["apellidoMaterno"]} ${_data.data["nombres"]}`;
        } else {
          _cliente = _data.data["razonSocial"];
        }
        _response = new APIResponse({
          success: true,
          data: _cliente,
        });
      })
      .catch((error) => {
        _response = new APIResponse({
          success: false,
          message: error,
        });
      });

    return _response;
  }
}

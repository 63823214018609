import { _getToken } from "../firebaseConfig";
import { APIResponse } from "../model/apiresponse";
import { browserLocalPersistence } from "firebase/auth";
import { child } from "firebase/database";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { DataSnapshot } from "firebase/database";
import { get } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { ref } from "firebase/database";
import { remove } from "firebase/database";
import { sendPasswordResetEmail } from "firebase/auth";
import { set } from "firebase/database";
import { setPersistence } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { signOut } from "firebase/auth";
import { updateProfile } from "firebase/auth";
import { UserCredential } from "firebase/auth";
import { Usuario } from "../model/usuario";

export class APIUsuario {
  async _createUser(
    email: string,
    password: string,
    nombres: string,
    perfil: string,
    sede: string,
    firma?: string
  ): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    await createUserWithEmailAndPassword(getAuth(), email, password)
      .then(async (_userCredential: UserCredential) => {
        let _usuario: Usuario = new Usuario({
          id: _userCredential.user.uid,
          nombres: nombres,
          perfil: perfil,
          sede: sede,
          correo: _userCredential.user.email!,
          firma: firma ?? null,
        });
        await updateProfile(getAuth().currentUser!, {
          displayName: nombres,
        })
          .then(async () => {
            await set(ref(getDatabase(), `usuarios/${_usuario.id}`), _usuario)
              .then(() => {
                _response = new APIResponse({
                  success: true,
                  message: "Usuario creado",
                });
              })
              .catch((_error) => {
                _response = new APIResponse({
                  success: false,
                  message: `Error al registrar usuario: ${_error}`,
                });
              });
          })
          .catch((_error) => {
            _response = new APIResponse({
              success: false,
              message: `Error al registrar usuario: ${_error}`,
            });
          });
      })
      .catch((error) => {
        _response = new APIResponse({
          success: false,
          codigo: error.code,
          message: error.message,
        });
      });
    return _response;
  }

  async _login(email: string, password: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    await setPersistence(getAuth(), browserLocalPersistence)
      .then(async () => {
        await signInWithEmailAndPassword(getAuth(), email, password)
          .then(async (_userCredential: UserCredential) => {
            let _usuario: Usuario = await this._getUser(
              _userCredential.user.uid
            );
            _response = new APIResponse({
              success: true,
              message: `Bienvenido ${_usuario.nombres}`,
              data: _usuario,
            });
            await _getToken()
              .then((_token: string) => {
                if (_token.length === 0) {
                  // _response = new APIResponse({
                  //   success: false,
                  //   message: `Error al obtener token, asegúrate de haber permitido las notificaciones de la página`,
                  // });
                  // this._logout(_userCredential.user.uid);
                  _response = new APIResponse({
                    success: true,
                    message: `Bienvenido ${_usuario.nombres}. No fue posible obtener tu token, no recibirás notificaciones de nuevas solicitudes`,
                    data: _usuario,
                  });
                } else {
                  set(ref(getDatabase(), `tokens/${_usuario.id}`), {
                    usuario: _usuario.id,
                    token: _token,
                    perfil: _usuario.perfil,
                    fechaCreacion: new Date().toLocaleString(),
                  })
                    .then(() => {})
                    .catch((_error) => {
                      _response = new APIResponse({
                        success: false,
                        message: `Error al registrar token: ${_error}. Por favor cierra tu sesión y vuelve a ingresar`,
                      });
                    });
                }
              })
              .catch((_error) => {
                _response = new APIResponse({
                  success: false,
                  message: `Error al obtener token: ${_error}. Por favor cierra tu sesión y vuelve a ingresar`,
                });
              });
          })
          .catch((error) => {
            _response = new APIResponse({
              success: false,
              codigo: error.code,
              message: error.message,
            });
          });
      })
      .catch((error) => {
        _response = new APIResponse({
          success: false,
          codigo: error.code,
          message: error.message,
        });
      });
    return _response;
  }

  async _getUser(_uid: string): Promise<Usuario> {
    let _usuario: Usuario = new Usuario({});
    await get(child(ref(getDatabase()), `usuarios/${_uid}`))
      .then(async (snapshot: DataSnapshot) => {
        if (snapshot.exists()) {
          _usuario = new Usuario(snapshot.val());
        }
      })
      .catch((error) => {});
    return _usuario;
  }

  async _logout(uid: string) {
    await this._deleteToken(uid)
      .then(async () => {
        await signOut(getAuth())
          .then(() => {
            console.log("Sesión cerrada");
          })
          .catch((error) => {
            console.log("Error al cerrar sesión");
          });
      })
      .catch((error) => {
        console.log("Error al borrar token");
      });
  }

  async _deleteToken(uid: string): Promise<any> {
    return remove(ref(getDatabase(), `tokens/${uid}`));
  }

  async _changePassword(correo: string): Promise<APIResponse> {
    let _response: APIResponse = new APIResponse({});
    await sendPasswordResetEmail(getAuth(), correo)
      .then(() => {
        _response = new APIResponse({
          success: true,
          message: "Revisa tu correo para cambiar tu contraseña",
        });
      })
      .catch((error) => {
        _response = new APIResponse({
          success: false,
          codigo: error.code,
          message: error.message,
        });
      });
    return _response;
  }

  _getCurrenUser = () =>
    new Promise((resolve) => {
      onAuthStateChanged(getAuth(), async (_user) => {
        resolve(_user);
      });
    });
}

import { Col } from "antd";
import { Form } from "antd";
import { FormInstance } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Row } from "antd";
import { Solicitud } from "../../model/solicitud";
import { Usuario } from "../../model/usuario";
import Global from "../../global/global";

function InformacionCliente({
  _solicitud,
  _usuario,
  _formCliente,
  _renderPDF,
}: {
  _solicitud: Solicitud;
  _usuario: Usuario;
  _formCliente: FormInstance;
  _renderPDF: Function;
}) {
  return (
    <Fragment>
      <Form
        form={_formCliente}
        name="form-cliente"
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
            <Form.Item label="DNI/RUC" name="clienteCodigo">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={16} xl={16} xxl={16}>
            <Form.Item label="Nombre/Raz&oacute;n Social" name="clienteNombre">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item
              label="L&iacute;nea de cr&eacute;dito"
              name="clienteLineaCredito"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item
              label="L&iacute;nea de cr&eacute;dito usada"
              name="clienteLineaCreditoUsada"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          {_usuario.perfil === Global.PERFILES[0] ? (
            ""
          ) : (
            <Fragment>
              <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="Situaci&oacute;n en SAP">
                  {_renderPDF(
                    _solicitud.clienteSituacionSAP!,
                    "Situación en SAP"
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <Form.Item label="Situaci&oacute;n en Sentinel">
                  {_renderPDF(
                    _solicitud.clienteSituacionSentinel!,
                    "Situación en Sentinel"
                  )}
                </Form.Item>
              </Col>
            </Fragment>
          )}
        </Row>
      </Form>
    </Fragment>
  );
}

export default InformacionCliente;

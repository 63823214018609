import { Tag } from "antd";

export default class Global {
  static API_KEY =
    "key=AAAA9ujNtVg:APA91bEhcLBKL_gyoETcZXqcqR9QvLZW-ctfg0ce3rgy1Wq4I1IDhUkrSvzDXikQ9XZPws5OP0IBM8O363I1Z0IhsDGvPvIdbeQL-hzmnld1aPm2VAwoHOA6a47a7Ae1PFWTWaixgK18";
  static API_PERU =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InNpc3RlbWFzMDMuM2FhbXNlcUBnbWFpbC5jb20ifQ.tJUudx5sCRHQN0XLAtSc1FD6Af-FJq0Xr4By2opgli8";
  static CODIGO_JUAN = "rwpKOmQhbQa4TXd56ske4rCCdY02";
  static CODIGO_RAFEL = "iES5QHu482egvZFSu0a2456zyz72";
  // static CELULAR_JUAN = "948316359";
  // static CELULAR_RAFEL = "946231588";
  static NOMBRE_EMPRESA = "AMSEQ S.A";
  static URL_COTIZACION =
    "https://www.3aamseq.com.pe/srss_cotizaciones/cotizacion/";
  static URL_FIRMAS = "https://www.3aamseq.com.pe/srss_cotizaciones/firmas/";
  static URL_INFORME_FINAL =
    "https://www.3aamseq.com.pe/srss_cotizaciones/informes_finales/";
  static URL_NOTIFICATIONS = "https://fcm.googleapis.com/fcm/send";
  // static URL_SEND_WHATSAPP_MESSAGE = "https://3aamseq.com.pe/srss_cotizaciones/api/send_whatsapp_message?";
  static URL_SITUACION_CLIENTE =
    "https://www.3aamseq.com.pe/srss_cotizaciones/situacion_cliente/";
  static URL_SUBIR_COTIZACION =
    "https://www.3aamseq.com.pe/srss_cotizaciones/api/upload.php";
  static URL_SUBIR_FIRMA =
    "https://www.3aamseq.com.pe/srss_cotizaciones/api/uploadFirma.php";
  static URL_UPLOAD_INFORME_FINAL =
    "https://www.3aamseq.com.pe/srss_cotizaciones/api/uploadInformeFinal.php";
  static URL_UPLOAD_SITUACION_CLIENTE =
    "https://www.3aamseq.com.pe/srss_cotizaciones/api/uploadSituacionCliente.php";

  static SEDES: string[] = [
    "AREQUIPA",
    "CHICLAYO",
    "CHICLAYO II",
    "CHIMBOTE",
    "HUANCAYO",
    "LIMA PUENTE PIEDRA",
    "LIMA SAN JUAN DE LURIGANCHO",
    "LIMA VILLA EL SALVADOR",
    "PIURA",
    "PIURA II",
    "TARAPOTO",
    "TARAPOTO II",
    "TRUJILLO",
    "TRUJILLO II",
    "OFICINA TRUJILLO",
  ];

  static PERFILES: string[] = [
    "ASESOR",
    "APROBADOR NIVEL 1",
    "APROBADOR NIVEL 2",
    "APROBADOR NIVEL 3",
    "APROBADOR NIVEL 4",
    "ADMINISTRADOR",
  ];

  static ESTADOS: string[] = [
    "PENDIENTE",
    "EN PROCESO",
    "APROBADA",
    "RECHAZADA",
    "ANULADA",
  ];

  static TIPOS: string[] = ["MENOR", "MEDIO", "MAYOR"];

  static CONDICION_PAGO: string[] = ["EFECTIVO", "TRANSFERENCIA", "CHEQUE"];

  static TIPO_GARANTIA: string[] = [
    "CHEQUE POR COMPRA",
    "CHEQUQE EN CARTERA",
    "LETRA",
    "OTROS",
  ];

  static FORMAT_DATE: {} = {
    hour12: false,
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  static _deleteDuplicated(duplicateArray: any[]) {
    var uniqueArray: any[] = [];
    var searchObject: any = {};
    for (var i in duplicateArray) {
      searchObject[duplicateArray[i]] = duplicateArray[i];
    }
    for (i in searchObject) {
      uniqueArray.push(searchObject[i] as never);
    }
    return uniqueArray;
  }

  static _renderEstado = (_estado: string) => {
    if (_estado === this.ESTADOS[0]) {
      return (
        <Tag id={_estado} color="#ffc107">
          <b>{_estado}</b>
        </Tag>
      );
    } else if (_estado === this.ESTADOS[1]) {
      return (
        <Tag id={_estado} color="#00bcd4">
          <b>{_estado}</b>
        </Tag>
      );
    } else if (_estado === this.ESTADOS[2]) {
      return (
        <Tag id={_estado} color="#4caf50">
          <b>{_estado}</b>
        </Tag>
      );
    } else if (_estado === this.ESTADOS[3]) {
      return (
        <Tag id={_estado} color="#f44336">
          <b>{_estado}</b>
        </Tag>
      );
    } else {
      return (
        <Tag id={_estado} color="#000000">
          <b>{_estado}</b>
        </Tag>
      );
    }
  };

  static _renderTipo = (_tipo: string) => {
    if (_tipo === this.TIPOS[2]) {
      return (
        <Tag id={_tipo} color="#f44336">
          <b>{_tipo}</b>
        </Tag>
      );
    } else if (_tipo === this.TIPOS[1]) {
      return (
        <Tag id={_tipo} color="#ffc107">
          <b>{_tipo}</b>
        </Tag>
      );
    } else {
      return (
        <Tag id={_tipo} color="#4caf50">
          <b>{_tipo}</b>
        </Tag>
      );
    }
  };
}

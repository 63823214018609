export interface APIDataI {
  success?: boolean;
  codigo?: string;
  message?: string;
  data?: any;
}

export class APIResponse implements APIDataI {
  success?: boolean;
  codigo?: string;
  message?: string;
  data?: any;

  constructor(data: APIDataI) {
    this.success = data.success;
    this.codigo = data.codigo;
    this.message = data.message;
    this.data = data.data;
  }
}

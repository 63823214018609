import { APIResponse } from "../../model/apiresponse";
import { APISolicitud } from "../../api/api_solicitud";
import { Button, Spin } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { CheckOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import { Col } from "antd";
import { Collapse } from "antd";
import { DataSnapshot } from "firebase/database";
import { DownloadOutlined } from "@ant-design/icons";
import { FilePdfFilled } from "@ant-design/icons";
import { Form } from "antd";
import { Fragment } from "react";
import { getDatabase } from "firebase/database";
import { Modal } from "antd";
import { onValue } from "firebase/database";
import { ref } from "firebase/database";
import { Row } from "antd";
import { Solicitud } from "../../model/solicitud";
import { Space } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import { Voto } from "../../model/voto";
import ErrorComponent from "../util/error.component";
import GestionSolicitudPage from "./gestion.page";
import Global from "../../global/global";
import InformacionAsesor from "../widgets/informacion_asesor";
import InformacionCliente from "../widgets/informacion_cliente";
import InformacionSolicitud from "../widgets/informacion_solicitud";
import InformacionVotos from "../widgets/informacion_votos";
import LeerPDF from "../widgets/leer_pdf";
import LoadComponent from "../util/load.component";
import SolicitudContext from "../../provider/solicitud-provider";
import InformeFinal from "../widgets/informe_final";
import { APIUsuario } from "../../api/api_usuario";
import UsuarioContext from "../../provider/usuario-provider";

function DetalleSolicitudPage({
  _setRoutes,
  _isMobile,
}: {
  _setRoutes: Function;
  _isMobile: boolean;
}) {
  const [_decision, _setDecision] = useState<number>(0);
  const [_existeVotoUsuario, _setExisteVotoUsuario] = useState<boolean>(false);
  const [_existeVotoNivel, _setExisteVotoNivel] = useState<boolean>(false);
  const [_formAsesor] = Form.useForm();
  const [_formCliente] = Form.useForm();
  const [_formSolicitud] = Form.useForm();
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const [_mounted, _setMounted] = useState<any>(null);
  const [_solicitud, _setSolicitud] = useState<Solicitud>(new Solicitud({}));
  const [_tituloModalPDF, _setTituloModalPDF] = useState<string>("");
  const [_usuario, _setUsuario] = useState<Usuario>(new Usuario({}));
  const [_urlPDF, _setUrlPDF] = useState<string>("");
  const [_visibleModalPDF, _setVisibleModalPDF] = useState<boolean>(false);
  const [_visibleModalVoto, _setVisibleModalVoto] = useState<boolean>(false);
  const [_votos, _setVotos] = useState<Voto[]>([]);
  const _apiSolicitud = useContext<APISolicitud>(SolicitudContext);
  const { _solicitudId } = useParams();
  const { Panel } = Collapse;
  const { Title } = Typography;
  let _navigate = useNavigate();
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);

  const _setDatos = useCallback(
    (_solicitud: Solicitud) => {
      _formAsesor.setFieldsValue({
        creadorNombre: _solicitud.creadorNombre,
        creadorSede: _solicitud.creadorSede,
      });
      _formCliente.setFieldsValue({
        clienteCodigo: _solicitud.clienteCodigo,
        clienteLineaCredito: `S/${_solicitud.clienteLineaCredito}`,
        clienteLineaCreditoUsada: `S/${_solicitud.clienteLineaCreditoUsada}`,
        clienteNombre: _solicitud.clienteNombre,
        clienteSituacionSAP: _solicitud.clienteSituacionSAP,
        clienteSituacionSentinel: _solicitud.clienteSituacionSentinel,
      });
      _formSolicitud.setFieldsValue({
        solicitudCondicionPago: _solicitud.solicitudCondicionPago,
        solicitudEstado: _solicitud.solicitudEstado,
        solicitudFechaCreacion: _solicitud.solicitudFechaCreacion,
        solicitudGarantia: _solicitud.solicitudGarantia,
        solicitudId: _solicitud.solicitudId,
        solicitudObservaciones: _solicitud.solicitudObservaciones,
        solicitudPDFCotizacion: _solicitud.solicitudPDFCotizacion,
        solicitudPlazo: _solicitud.solicitudPlazo,
        solicitudPorcentaje: `${_solicitud.solicitudPorcentaje} %`,
        solicitudTipo: _solicitud.solicitudTipo,
        solicitudTotalCotizacion: `S/${_solicitud.solicitudTotalCotizacion}`,
      });
    },
    [_formAsesor, _formCliente, _formSolicitud]
  );

  const _verificarVotoUsuario = useCallback(
    async (_solicitud: string, _usuario: string) => {
      _setExisteVotoUsuario(
        await _apiSolicitud._verificarVotoUsuario(_solicitud, _usuario)
      );
    },
    [_apiSolicitud]
  );

  const _verificarVotoNivel = useCallback(
    async (_solicitud: string, _perfil: string) => {
      _setExisteVotoNivel(
        await _apiSolicitud._verificarVotoNivel(_solicitud, _perfil)
      );
    },
    [_apiSolicitud]
  );

  const _getVotos = useCallback(async (_solicitud: Solicitud) => {
    let _votos: Voto[] = [];
    onValue(
      ref(getDatabase(), `votos/${_solicitud.solicitudId}`),
      (_snapshot: DataSnapshot) => {
        _votos = [];
        if (_snapshot.exists()) {
          _snapshot.forEach((_documents: any) => {
            let _voto: Voto = new Voto(_documents.val());
            _voto.codigo = _documents.ref._path.pieces_[2];
            _votos.push(_voto);
          });
        }
        _setVotos(_votos);
      }
    );
  }, []);

  const _getSolicitud = useCallback(
    (_usuario: Usuario, _solicitudId: string) => {
      _setLoad(true);
      let _solicitud: Solicitud = new Solicitud({ solicitudId: _solicitudId });
      if (_usuario.perfil === Global.PERFILES[0]) {
        onValue(
          ref(getDatabase(), `solicitudes/${_usuario.id}/${_solicitudId}`),
          (_snapshot: DataSnapshot) => {
            if (_snapshot.exists()) {
              _solicitud = new Solicitud(_snapshot.val());
              _solicitud.solicitudId = _solicitudId;
              _setSolicitud(_solicitud);
              _setDatos(_solicitud);
              _setLoad(false);
            } else {
              _modal.error({
                title: Global.NOMBRE_EMPRESA,
                content: "No se econtró la solicitud",
                centered: true,
                onOk: () => {
                  _navigate("/reporte");
                },
              });
            }
          }
        );
      } else {
        let _exists: boolean = false;
        onValue(
          ref(getDatabase(), `solicitudes`),
          (_snapshot: DataSnapshot) => {
            if (_snapshot.exists()) {
              _snapshot.forEach((_documents: any) => {
                _documents.forEach((_data: any) => {
                  if (_data.ref._path.pieces_[2] === _solicitudId) {
                    _solicitud = new Solicitud(_data.val());
                    _solicitud.solicitudId = _solicitudId;
                    _exists = true;
                  }
                });
              });
              if (_exists) {
                _setSolicitud(_solicitud);
                _setDatos(_solicitud);
                _getVotos(_solicitud);
                _verificarVotoUsuario(_solicitudId, _usuario.id!);
                _verificarVotoNivel(_solicitudId, _usuario.perfil!);
                _setLoad(false);
              } else {
                _modal.error({
                  title: Global.NOMBRE_EMPRESA,
                  content: "No se encontró la solicitud",
                  centered: true,
                  onOk: () => {
                    _navigate("/reporte");
                  },
                });
              }
            } else {
              _modal.error({
                title: Global.NOMBRE_EMPRESA,
                content: "No se encontró la solicitud",
                centered: true,
                onOk: () => {
                  _navigate("/reporte");
                },
              });
            }
          }
        );
      }
    },
    [
      _getVotos,
      _modal,
      _navigate,
      _setDatos,
      _verificarVotoNivel,
      _verificarVotoUsuario,
    ]
  );

  const _renderPDF = (_url: string, _tituloModalPDF: string) => {
    if (!_url) {
      return "No se encontró el documento";
    }
    return (
      <Space>
        <Tooltip title="Ver">
          <FilePdfFilled
            onClick={() => {
              _setVisibleModalPDF(true);
              _setUrlPDF(_url);
              _setTituloModalPDF(_tituloModalPDF);
            }}
            style={{
              cursor: "pointer",
              fontSize: "x-large",
              color: "rgba(244, 67, 54, 1)",
            }}
          />
        </Tooltip>
        <Tooltip title="Descargar">
          <a href={_url} target="_blank" rel="noreferrer" download>
            <DownloadOutlined
              style={{
                cursor: "pointer",
                fontSize: "x-large",
                color: "rgba(0, 150, 136, 1)",
              }}
            />
          </a>
        </Tooltip>
      </Space>
    );
  };

  const _guardarObservaciones = async (_observaciones: string) => {
    _setLoad(true);
    _solicitud.solicitudObservaciones = _observaciones;
    let _response: APIResponse = await _apiSolicitud._updateSolicitud(
      _solicitud
    );
    if (_response.success) {
      _modal.success({
        title: Global.NOMBRE_EMPRESA,
        content: "Solicitud actualizada",
      });
    } else {
      _modal.error({
        title: Global.NOMBRE_EMPRESA,
        content: _response.message,
      });
    }
  };

  const _subirInformeFinal = (_file: any) => {
    _setLoad(true);
    let _formData: FormData = new FormData();
    let _xhttp: XMLHttpRequest = new XMLHttpRequest();
    _formData.append("file", _file);
    _xhttp.open("POST", Global.URL_UPLOAD_INFORME_FINAL, true);
    _xhttp.onreadystatechange = async () => {
      if (_xhttp.readyState === 4 && _xhttp.status === 200) {
        let _response: any = JSON.parse(_xhttp.responseText);
        if (_response.success === 1) {
          _solicitud.solicitudInformeFinal = `${Global.URL_INFORME_FINAL}${_response.message}`;
          let _update: APIResponse = await _apiSolicitud._updateSolicitud(
            _solicitud
          );
          if (_update.success) {
            _modal.success({
              title: Global.NOMBRE_EMPRESA,
              content: "Solicitud actualizada",
            });
          } else {
            _modal.error({
              title: Global.NOMBRE_EMPRESA,
              content: _update.message,
            });
          }
        } else {
          _modal.error({
            title: Global.NOMBRE_EMPRESA,
            content:
              "Ocurrió un error al cargar el archivo, inténtalo de nuevo",
            centered: true,
          });
          _setLoad(false);
        }
      }
    };
    _xhttp.send(_formData);
  };

  const _getCurrentUser = useCallback(async () => {
    _apiUsuario
      ._getCurrenUser()
      .then(async (_user: any) => {
        if (_user) {
          let _usuario: Usuario = await _apiUsuario._getUser(_user.uid);
          _setUsuario(_usuario);
          _getSolicitud(_usuario, _solicitudId!);
        } else {
          _navigate("/login");
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }, [_apiUsuario, _getSolicitud, _navigate, _solicitudId]);

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    _setRoutes(["Inicio", "Reporte", _solicitudId]);

    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser, _setRoutes, _solicitudId]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return _usuario.perfil ? (
    <Fragment>
      <Spin spinning={_load}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Title level={3} className="titulo">
              Detalle de solicitud
            </Title>
            <Title level={5} className="subtitulo">
              C&oacute;digo: {_solicitud.solicitudId}
            </Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            {_usuario.perfil === Global.PERFILES[0] ? (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                defaultActiveKey={["1", "2", "3"]}
              >
                <Panel
                  header="Datos del asesor(a)"
                  key="1"
                  style={{ display: "none" }}
                >
                  <InformacionAsesor _formAsesor={_formAsesor} />
                </Panel>
                <Panel header="Datos del cliente" key="2">
                  <InformacionCliente
                    _solicitud={_solicitud}
                    _usuario={_usuario}
                    _formCliente={_formCliente}
                    _renderPDF={_renderPDF}
                  />
                </Panel>
                <Panel header="Datos de la solicitud" key="3">
                  <InformacionSolicitud
                    _solicitud={_solicitud}
                    _formSolicitud={_formSolicitud}
                    _renderPDF={_renderPDF}
                  />
                </Panel>
              </Collapse>
            ) : (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                defaultActiveKey={["1", "2", "3", "4", "5"]}
              >
                <Panel header="Datos del asesor(a)" key="1">
                  <InformacionAsesor _formAsesor={_formAsesor} />
                </Panel>
                <Panel header="Datos del cliente" key="2">
                  <InformacionCliente
                    _solicitud={_solicitud}
                    _usuario={_usuario}
                    _formCliente={_formCliente}
                    _renderPDF={_renderPDF}
                  />
                </Panel>
                <Panel header="Datos de la solicitud" key="3">
                  <InformacionSolicitud
                    _solicitud={_solicitud}
                    _formSolicitud={_formSolicitud}
                    _renderPDF={_renderPDF}
                  />
                </Panel>
                {_solicitud.solicitudEstado === Global.ESTADOS[0] ? (
                  ""
                ) : (
                  <Panel header="Datos de la votación" key="4">
                    <InformacionVotos _votos={_votos} />
                  </Panel>
                )}
                {(_usuario.perfil === Global.PERFILES[1] ||
                  _usuario.perfil === Global.PERFILES[5]) &&
                (_solicitud.solicitudEstado === Global.ESTADOS[2] ||
                  _solicitud.solicitudEstado === Global.ESTADOS[3] ||
                  _solicitud.solicitudEstado === Global.ESTADOS[4]) ? (
                  <Panel header="Informe final" key="5">
                    <InformeFinal
                      _solicitud={_solicitud}
                      _votos={_votos}
                      _guardarObservaciones={_guardarObservaciones}
                      _subirInformeFinal={_subirInformeFinal}
                      _isMobile={_isMobile}
                    />
                  </Panel>
                ) : (
                  ""
                )}
              </Collapse>
            )}
          </Col>
          {_usuario.perfil === Global.PERFILES[0] ||
          _existeVotoUsuario ||
          _solicitud.solicitudEstado === Global.ESTADOS[2] ||
          _solicitud.solicitudEstado === Global.ESTADOS[3] ||
          _solicitud.solicitudEstado === Global.ESTADOS[4] ||
          _existeVotoNivel ? (
            ""
          ) : (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="col-padding-detalle-solicitud"
            >
              <Space direction={_isMobile ? "vertical" : "horizontal"}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  style={{ width: 120 }}
                  onClick={() => {
                    _setDecision(1);
                    _setVisibleModalVoto(true);
                  }}
                >
                  Aprobar
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  style={{ width: 120 }}
                  onClick={() => {
                    _setDecision(0);
                    _setVisibleModalVoto(true);
                  }}
                >
                  Rechazar
                </Button>
                {_usuario.perfil === Global.PERFILES[1] ? (
                  <Button
                    icon={<StopOutlined />}
                    style={{ width: 120 }}
                    onClick={() => {
                      _setDecision(2);
                      _setVisibleModalVoto(true);
                    }}
                    danger
                  >
                    Anular
                  </Button>
                ) : (
                  ""
                )}
              </Space>
            </Col>
          )}
        </Row>

        {/* Leer PDF */}
        <Modal
          title={_tituloModalPDF}
          visible={_visibleModalPDF}
          onCancel={() => {
            _setVisibleModalPDF(false);
            _setUrlPDF("");
          }}
          style={{ top: 10 }}
          width={650}
          className="ant-modal-pdf"
          footer={[]}
          centered
        >
          <LeerPDF _url={_urlPDF} />
        </Modal>

        {/* Emitir voto */}
        <Modal
          title={
            _decision === 0
              ? "RECHAZAR SOLICITUD"
              : _decision === 1
              ? "APROBAR SOLICITUD"
              : "ANULAR SOLICITUD"
          }
          visible={_visibleModalVoto}
          footer={[]}
          className="modal-voto-detalle-solicitud"
          onCancel={() => {
            _setVisibleModalVoto(false);
          }}
          centered
          maskClosable={false}
          closable={false}
        >
          <GestionSolicitudPage
            _decision={_decision}
            _usuario={_usuario}
            _setVisibleModalVoto={_setVisibleModalVoto}
            _solicitud={_solicitud}
            _apiSolicitud={_apiSolicitud}
            _verificarVotoUsuario={_verificarVotoUsuario}
            _verificarVotoNivel={_verificarVotoNivel}
          />
        </Modal>
        {_contextHolder}
      </Spin>
    </Fragment>
  ) : (
    <LoadComponent />
  );
}

export default DetalleSolicitudPage;

export interface VotoDataI {
  codigo?: string | null;
  solicitudCodigo: string;
  usuarioCodigo: string;
  usuarioPerfil: string;
  usuarioFirma: string;
  usuarioNombres: string;
  voto: number;
  fecha: string;
  comentario: string;
}

export class Voto implements VotoDataI {
  codigo?: string | null;
  solicitudCodigo: string;
  usuarioCodigo: string;
  usuarioPerfil: string;
  usuarioFirma: string;
  usuarioNombres: string;
  voto: number;
  fecha: string;
  comentario: string;

  constructor(data: VotoDataI) {
    this.codigo = data.codigo;
    this.solicitudCodigo = data.solicitudCodigo;
    this.usuarioCodigo = data.usuarioCodigo;
    this.usuarioPerfil = data.usuarioPerfil;
    this.usuarioFirma = data.usuarioFirma;
    this.usuarioNombres = data.usuarioNombres;
    this.voto = data.voto;
    this.fecha = data.fecha;
    this.comentario = data.comentario;
  }
}

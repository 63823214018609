import { Spin } from "antd";

function LoadComponent() {
  return (
    <div id="load-component">
      <Spin />
    </div>
  );
}

export default LoadComponent;

import { APIUsuario } from "../../api/api_usuario";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Spin } from "antd";
import { Typography } from "antd";
import { useCallback } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ErrorComponent from "../util/error.component";
import LoadComponent from "../util/load.component";
import logo from "../../asset/logo_azul.svg";
import UsuarioContext from "../../provider/usuario-provider";
import Global from "../../global/global";

function LoginPage() {
  const [_mounted, _setMounted] = useState<any>(null);
  const [_load, _setLoad] = useState<boolean>(false);
  const [_modal, _contextHolder] = Modal.useModal();
  const { Title } = Typography;
  const _apiUsuario = useContext<APIUsuario>(UsuarioContext);
  let _navigate = useNavigate();

  const _getCurrentUser = useCallback(async () => {
    _apiUsuario
      ._getCurrenUser()
      .then((_user: any) => {
        if (_user) {
          _navigate("/reporte");
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }, [_apiUsuario, _navigate]);

  const login = async (values: any) => {
    _setLoad(true);
    let _result: any = await _apiUsuario._login(
      values["correo"],
      values["password"]
    );
    _setLoad(false);
    if (_result.success) {
      _modal.success({
        title: Global.NOMBRE_EMPRESA,
        content: _result.message,
        centered: true,
        onOk: () => {
          _navigate("/reporte");
        },
      });
    } else {
      _modal.error({
        title: Global.NOMBRE_EMPRESA,
        content: _result.message,
        centered: true,
      });
    }
  };

  useEffect(() => {
    _setMounted(true);
    _getCurrentUser();
    return () => {
      _setMounted(false);
    };
  }, [_getCurrentUser]);

  if (_mounted === null) {
    return <LoadComponent />;
  }

  if (!_mounted) {
    return <ErrorComponent />;
  }

  return (
    <Fragment>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="login-page-col-logo"
        >
          <img
            src={logo}
            alt="3AAMSEQ"
            title="3AAMSEQ"
            width={300}
            loading="lazy"
            className="login-page-logo"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="login-page-col-title"
        >
          <Title level={4} className="login-page-title">
            SISTEMA DE REGISTRO DE <br /> SOLICITUDES DE SOBREGIRO
          </Title>
          <Title level={4} className="login-page-title">
            Inicio de sesi&oacute;n
          </Title>
          <Title level={5} className="login-page-title">
            Ingresa los datos solicitados para iniciar sesi&oacute;n
          </Title>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Spin spinning={_load}>
            <Form
              name="form-login"
              layout="vertical"
              onFinish={login}
              autoComplete="off"
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Correo"
                    name="correo"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa tu correo!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    label="Contraseña"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "¡Por favor ingresa tu contraseña!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Iniciar sesi&oacute;n
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
      {_contextHolder}
    </Fragment>
  );
}

export default LoginPage;

import { APIResponse } from "../../model/apiresponse";
import { APISolicitud } from "../../api/api_solicitud";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { useEffect } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Solicitud } from "../../model/solicitud";
import { Space } from "antd";
import { Spin } from "antd";
import { useState } from "react";
import { Usuario } from "../../model/usuario";
import { Voto } from "../../model/voto";
import Global from "../../global/global";

function GestionSolicitudPage({
  _decision,
  _usuario,
  _setVisibleModalVoto,
  _solicitud,
  _apiSolicitud,
  _verificarVotoUsuario,
  _verificarVotoNivel,
}: {
  _decision: number;
  _usuario: Usuario;
  _setVisibleModalVoto: Function;
  _solicitud: Solicitud;
  _apiSolicitud: APISolicitud;
  _verificarVotoUsuario: Function;
  _verificarVotoNivel: Function;
}) {
  const [_modal, _contextHolder] = Modal.useModal();
  const [_accion, _setAccion] = useState<string>("");
  const [_load, _setLoad] = useState<boolean>(false);
  const [_fileSAP, _setFileSAP] = useState<File>();
  const [_fileSentinel, _setFileSentinel] = useState<File>();
  const [_fileNameSAP, _setFileNameSAP] = useState("Seleccionar archivo");
  const [_fileNameSentinel, _setFileNameSentinel] = useState(
    "Seleccionar archivo"
  );
  const [_formVoto] = Form.useForm();

  const _onChangeFileSAP = async (values: any) => {
    _setFileNameSAP(values.target.files[0].name);
    _setFileSAP(values.target.files[0]);
  };

  const _onChangeFileSentinel = async (values: any) => {
    _setFileNameSentinel(values.target.files[0].name);
    _setFileSentinel(values.target.files[0]);
  };

  const _subirArchivos = () => {
    _setLoad(true);
    let _files: File[] = [_fileSAP!, _fileSentinel!];
    let _rutas: string[] = [];
    let _formData: FormData = new FormData();
    let _xhttp: XMLHttpRequest = new XMLHttpRequest();
    _files.map((file: File, index: number) =>
      _formData.append(`file${index}`, file)
    );
    _xhttp.open("POST", Global.URL_UPLOAD_SITUACION_CLIENTE, true);
    _xhttp.onreadystatechange = async () => {
      if (_xhttp.readyState === 4 && _xhttp.status === 200) {
        let _response: any = JSON.parse(_xhttp.responseText);
        if (_response.success === 1) {
          _response.message.forEach((_ruta: string, _index: number) => {
            _rutas.push(`${Global.URL_SITUACION_CLIENTE}${_ruta}`);
          });
          _solicitud.clienteSituacionSAP = _rutas[0];
          _solicitud.clienteSituacionSentinel = _rutas[1];
          _emitirVoto();
        } else {
          _modal.error({
            title: Global.NOMBRE_EMPRESA,
            content:
              "Ocurrió un error al cargar los archivos, inténtalo de nuevo 1",
            centered: true,
          });
          _setLoad(false);
        }
      }
    };
    _xhttp.send(_formData);
  };

  const _emitirVoto = async () => {
    _setVisibleModalVoto(false);
    let _date: Date = new Date();
    let _fecha: string = _date.toLocaleString("es-ES", Global.FORMAT_DATE);
    let _voto: Voto = new Voto({
      codigo: null,
      solicitudCodigo: _solicitud.solicitudId!,
      usuarioCodigo: _usuario.id!,
      usuarioPerfil: _usuario.perfil!,
      usuarioFirma: _usuario.firma!,
      usuarioNombres: _usuario.nombres!,
      voto: _decision,
      fecha: _fecha,
      comentario: _formVoto.getFieldValue("comentario"),
    });
    let _result: APIResponse = await _apiSolicitud._emitirVoto(
      _voto,
      _solicitud,
      _usuario
    );
    if (_result.success) {
      _verificarVotoUsuario(_solicitud.solicitudId, _usuario.id);
      _verificarVotoNivel(_solicitud.solicitudId, _usuario.perfil);
      _modal.success({
        title: Global.NOMBRE_EMPRESA,
        content: _result.message,
        centered: true,
        onOk: () => {
          _resetForm();
        },
      });
    } else {
      _modal.error({
        title: Global.NOMBRE_EMPRESA,
        content: _result.message,
        centered: true,
      });
    }
  };

  const _resetForm = () => {
    _setVisibleModalVoto(false);
    _formVoto.resetFields();
    _setFileNameSAP("Seleccionar archivo");
    _setFileNameSentinel("Seleccionar archivo");
    _setFileSAP(undefined);
    _setFileSentinel(undefined);
  };

  useEffect(() => {
    _setAccion(
      _decision === 0 ? "RECHAZAR" : _decision === 1 ? "APROBAR" : "ANULAR"
    );
  }, [_decision]);

  return (
    <Fragment>
      <Spin spinning={_load}>
        <Form
          form={_formVoto}
          name="form-voto"
          layout="vertical"
          autoComplete="off"
          onFinish={() => {
            _modal.confirm({
              title: Global.NOMBRE_EMPRESA,
              content: `¿Está seguro de ${_accion} la solicitud?`,
              centered: true,
              okText: `Si, ${_accion}`,
              cancelText: "No, cancelar",
              onOk:
                _usuario.perfil === Global.PERFILES[1] &&
                (_decision === 0 || _decision === 1)
                  ? _subirArchivos
                  : _emitirVoto,
            });
          }}
        >
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="col-padding-detalle-solicitud"
            >
              <Form.Item
                label="Comentario"
                name="comentario"
                rules={[
                  {
                    required: true,
                    message: "¡Por favor ingrese su comentario!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            {_usuario.perfil === Global.PERFILES[1] && _decision !== 2 ? (
              <Fragment>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  className="col-padding-detalle-solicitud"
                >
                  <Form.Item
                    label="Situación SAP"
                    name="situacionSAP"
                    rules={
                      _usuario.perfil === Global.PERFILES[1] && _decision !== 2
                        ? [
                            {
                              required: true,
                              message: "¡Por favor sube el archivo de SAP!",
                            },
                          ]
                        : []
                    }
                  >
                    <label className="custom-label">
                      {_fileNameSAP}
                      <Input
                        type="file"
                        hidden
                        onChange={_onChangeFileSAP}
                        accept="application/pdf"
                      />
                    </label>
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  className="col-padding-detalle-solicitud"
                >
                  <Form.Item
                    label="Situación Sentinel"
                    name="situacionSentinel"
                    rules={
                      _usuario.perfil === Global.PERFILES[1] && _decision !== 2
                        ? [
                            {
                              required: true,
                              message:
                                "¡Por favor sube el archivo de Sentinel!",
                            },
                          ]
                        : []
                    }
                  >
                    {/* <Input /> */}
                    <label className="custom-label">
                      {_fileNameSentinel}
                      <Input
                        type="file"
                        hidden
                        onChange={_onChangeFileSentinel}
                        accept="application/pdf"
                      />
                    </label>
                  </Form.Item>
                </Col>
              </Fragment>
            ) : (
              ""
            )}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="col-padding-detalle-solicitud"
            >
              <Form.Item>
                <Space>
                  <Button onClick={_resetForm}>Cancelar</Button>
                  <Button type="primary" htmlType="submit">
                    Ok
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      {_contextHolder}
    </Fragment>
  );
}

export default GestionSolicitudPage;

import { Table } from "antd";
import { Voto } from "../../model/voto";
import Global from "../../global/global";

function InformacionVotos({ _votos }: { _votos: Voto[] }) {
  return (
    <Table
      pagination={{
        position: ["bottomCenter"],
        simple: false,
        size: "small",
      }}
      bordered
      key="tablaSolicitudes"
      rowKey="codigo"
      columns={[
        {
          title: "Codigo",
          key: "codigo",
          dataIndex: "codigo",
          align: "center",
          sorter: (a: Voto, b: Voto) => a.codigo!.localeCompare(b.codigo!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Solicitud",
          key: "solicitudCodigo",
          dataIndex: "solicitudCodigo",
          align: "center",
          sorter: (a: Voto, b: Voto) =>
            a.solicitudCodigo!.localeCompare(b.solicitudCodigo!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Usuario Código",
          key: "usuarioCodigo",
          dataIndex: "usuarioCodigo",
          align: "center",
          sorter: (a: Voto, b: Voto) =>
            a.usuarioCodigo!.localeCompare(b.usuarioCodigo!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Nombre",
          key: "usuarioNombres",
          dataIndex: "usuarioNombres",
          align: "center",
          sorter: (a: Voto, b: Voto) =>
            a.usuarioNombres!.localeCompare(b.usuarioNombres!),
          showSorterTooltip: false,
        },
        {
          title: "Voto",
          key: "voto",
          dataIndex: "voto",
          align: "center",
          sorter: (a: Voto, b: Voto) => a.voto! - b.voto!,
          showSorterTooltip: false,
          render: (_voto, _, __) => {
            let _estado: string = "";
            if (_voto === 0) {
              _estado = Global.ESTADOS[3];
            } else if (_voto === 1) {
              _estado = Global.ESTADOS[2];
            } else {
              _estado = Global.ESTADOS[4];
            }
            return Global._renderEstado(_estado);
          },
        },

        {
          title: "Fecha/Hora",
          key: "fecha",
          dataIndex: "fecha",
          align: "center",
          sorter: (a: Voto, b: Voto) => a.fecha!.localeCompare(b.fecha!),
          showSorterTooltip: false,
          className: "hide-column",
        },
        {
          title: "Comentario",
          key: "comentario",
          dataIndex: "comentario",
          align: "center",
          sorter: (a: Voto, b: Voto) =>
            a.comentario!.localeCompare(b.comentario!),
          showSorterTooltip: false,
        },
      ]}
      dataSource={_votos?.map((_voto: Voto) => ({
        ..._voto,
      }))}
    />
  );
}

export default InformacionVotos;

import { APIResponse } from "../model/apiresponse";
import { child } from "firebase/database";
import { DataSnapshot } from "firebase/database";
import { get } from "firebase/database";
import { getDatabase } from "firebase/database";
import { push } from "firebase/database";
import { ref } from "firebase/database";
import { set } from "firebase/database";
import { Solicitud } from "../model/solicitud";
import { Usuario } from "../model/usuario";
import { Voto } from "../model/voto";
import Global from "../global/global";
import axios from "axios";
import { Token } from "../model/token";

export class APISolicitud {
  async _verificarSolicitudCliente(
    _usuario: string,
    _cliente: string
  ): Promise<boolean> {
    let _exists: boolean = false;
    let _solicitudes: Solicitud[] = [];

    await get(child(ref(getDatabase()), `solicitudes/${_usuario}`))
      .then((_snapshot: DataSnapshot) => {
        if (_snapshot.exists()) {
          _snapshot.forEach((_document: any) => {
            let _solicitud: Solicitud = new Solicitud(_document.val());
            _solicitud.solicitudId = _document.ref._path.pieces_[2];
            _solicitudes.push(_solicitud);
          });
          _solicitudes.forEach((_solicitud: Solicitud) => {
            if (
              _solicitud.clienteCodigo === _cliente &&
              (_solicitud.solicitudEstado === Global.ESTADOS[0] ||
                _solicitud.solicitudEstado === Global.ESTADOS[1])
            ) {
              _exists = true;
            }
          });
        }
      })
      .catch((_error) => {});

    return _exists;
  }

  async _createSolicitud(
    _solicitud: Solicitud,
    _usuario: Usuario
  ): Promise<APIResponse> {
    let _result: APIResponse = new APIResponse({});

    await push(ref(getDatabase(), `solicitudes/${_usuario.id}`), _solicitud)
      .then(async () => {
        let _token: string = await this._getToken({
          _perfil: Global.PERFILES[1],
        });
        await this._sendNotification(
          _token,
          "NUEVA SOLICITUD",
          `El asesor ${_solicitud.creadorNombre} ha registrado una solicitud para el cliente ${_solicitud.clienteNombre}.`
        );
        _result = new APIResponse({
          success: true,
          message: "Solicitud registrada",
        });
      })
      .catch((error: any) => {
        _result = new APIResponse({
          success: false,
          message: error,
        });
      });

    return _result;
  }

  async _emitirVoto(
    _voto: Voto,
    _solicitud: Solicitud,
    _usuario: Usuario
  ): Promise<APIResponse> {
    let _result: APIResponse = new APIResponse({});
    await push(ref(getDatabase(), `votos/${_voto.solicitudCodigo}`), _voto)
      .then(async () => {
        // OBTENER TOKENS
        let _tokenAsesor: string = await this._getToken({
          _uid: _solicitud.creadorCodigo!,
        });
        let _tokenAPN1: string = await this._getToken({
          _perfil: Global.PERFILES[1],
        });
        let _tokenAPN2: string = await this._getToken({
          _perfil: Global.PERFILES[2],
        });
        let _tokenAPN3: string = await this._getToken({
          _perfil: Global.PERFILES[3],
        });
        let _tokenR: string = await this._getToken({
          _uid: Global.CODIGO_RAFEL,
        });
        let _tokenJ: string = await this._getToken({
          _uid: Global.CODIGO_JUAN,
        });
        let _estado: string = "";
        let _mensaje: string = "";

        // SI EL VOTO ES 2, LA SOLICITUD SE ANULA Y SE REGISTRA EN EL COMENTARIO EL MOTIVO
        if (_voto.voto === 2) {
          _solicitud.solicitudEstado = Global.ESTADOS[4];
          _solicitud.solicitudObservaciones = _voto.comentario;
          this._updateSolicitud(_solicitud);
          await this._sendNotification(
            _tokenAsesor,
            `SOLICITUD ${Global.ESTADOS[4]}`,
            `La solicitud para el cliente ${_solicitud.clienteNombre} ha sido ${Global.ESTADOS[4]} por el siguiente motivo: ${_voto.comentario}`
          );
        }

        // TODAS LAS SOLICITUDES SE PUEDEN RECHAZAR POR EL APROBADOR NIVEL 1
        else if (_usuario.perfil === Global.PERFILES[1] && _voto.voto === 0) {
          _solicitud.solicitudEstado = Global.ESTADOS[3];
          _solicitud.solicitudObservaciones = _voto.comentario;
          this._updateSolicitud(_solicitud);
          await this._sendNotification(
            _tokenAsesor,
            `SOLICITUD ${Global.ESTADOS[3]}`,
            `La solicitud para el cliente ${_solicitud.clienteNombre} ha sido ${Global.ESTADOS[3]} por el siguiente motivo: ${_voto.comentario}`
          );
        }

        // SI LA SOLICITUD ES TIPO "MENOR", SE APRUEBA O RECHAZA CON EL APROBADOR NIVEL 1
        else if (
          _solicitud.solicitudTipo === Global.TIPOS[0] &&
          _usuario.perfil === Global.PERFILES[1]
        ) {
          _solicitud.solicitudEstado =
            _voto.voto === 1 ? Global.ESTADOS[2] : Global.ESTADOS[3];
          _estado = _solicitud.solicitudEstado;
          _mensaje =
            _voto.voto === 1
              ? `La solicitud para el cliente ${_solicitud.clienteNombre} ha sido ${Global.ESTADOS[2]}.`
              : `La solicitud para el cliente ${_solicitud.clienteNombre} ha sido ${Global.ESTADOS[3]} por el siguiente motivo: ${_voto.comentario}`;
          this._updateSolicitud(_solicitud);
          await this._sendNotification(
            _tokenAsesor,
            `SOLICITUD ${_estado}`,
            _mensaje
          );
        }

        // SI LA SOLICITUD ES TIPO "MEDIO", SE APRUEBA O RECHAZA CON EL APROBADOR NIVEL 2
        else if (
          _solicitud.solicitudTipo === Global.TIPOS[1] &&
          _usuario.perfil === Global.PERFILES[2]
        ) {
          _solicitud.solicitudEstado =
            _voto.voto === 1 ? Global.ESTADOS[2] : Global.ESTADOS[3];
          _estado = _solicitud.solicitudEstado;
          _mensaje =
            _voto.voto === 1
              ? `La solicitud para el cliente ${_solicitud.clienteNombre} ha sido ${Global.ESTADOS[2]}.`
              : `La solicitud para el cliente ${_solicitud.clienteNombre} ha sido ${Global.ESTADOS[3]}.`;

          this._updateSolicitud(_solicitud);
          await this._sendNotification(
            _tokenAsesor,
            `SOLICITUD ${_estado}`,
            _mensaje
          );
          await this._sendNotification(
            _tokenAPN1,
            `SOLICITUD ${_estado}`,
            _mensaje
          );
        }

        // EN CUALQUIER OTRO CASO, SE CUENTAN LOS VOTOS Y SE CALCULA EL ESTADO
        else {
          let _votos: Voto[] = [];
          let _votantes: string[] = [];
          await this._getVotos(_solicitud)
            .then(async (_result: APIResponse) => {
              if (_result.success) {
                _votos = _result.data;
                _estado = await this._getEstado(_votos);
                _solicitud.solicitudEstado = _estado;
                this._updateSolicitud(_solicitud);
                // SOLICITUD EN PROCESO
                if (
                  _estado === Global.ESTADOS[0] ||
                  _estado === Global.ESTADOS[1]
                ) {
                  if (_usuario.perfil === Global.PERFILES[1]) {
                    _mensaje = `El asesor ${_solicitud.creadorNombre} ha registrado una solicitud para el cliente ${_solicitud.clienteNombre}.`;
                    if (_solicitud.solicitudTipo === Global.TIPOS[1]) {
                      await this._sendNotification(
                        _tokenAPN2,
                        "NUEVA SOLICITUD",
                        _mensaje
                      );
                    } else {
                      await this._sendNotification(
                        _tokenAPN2,
                        "NUEVA SOLICITUD",
                        _mensaje
                      );
                      await this._sendNotification(
                        _tokenAPN3,
                        "NUEVA SOLICITUD",
                        _mensaje
                      );
                      await this._sendNotification(
                        _tokenJ,
                        "NUEVA SOLICITUD",
                        _mensaje
                      );
                      await this._sendNotification(
                        _tokenR,
                        "NUEVA SOLICITUD",
                        _mensaje
                      );
                    }
                  } else if (
                    _usuario.perfil === Global.PERFILES[2] ||
                    _usuario.perfil === Global.PERFILES[3]
                  ) {
                    _mensaje = `Hay una solicitud pendiente de su atención. ${_usuario.nombres} ya emitió su voto.`;
                    _votos.forEach((_voto: Voto) => {
                      _votantes.push(_voto.usuarioCodigo);
                    });
                    if (!_votantes.includes(Global.CODIGO_RAFEL)) {
                      await this._sendNotification(
                        _tokenR,
                        "NUEVA SOLICITUD",
                        _mensaje
                      );
                    }
                    if (!_votantes.includes(Global.CODIGO_JUAN)) {
                      await this._sendNotification(
                        _tokenJ,
                        "NUEVA SOLICITUD",
                        _mensaje
                      );
                    }
                  }
                } else {
                  await this._sendNotification(
                    _tokenAPN1,
                    `SOLICITUD ${_estado}`,
                    `La solicitud del asesor ${_solicitud.creadorNombre} para el cliente ${_solicitud.clienteNombre} ha sido ${_estado}.`
                  );
                  await this._sendNotification(
                    _tokenAsesor,
                    `SOLICITUD ${_estado}`,
                    `La solicitud para el cliente ${_solicitud.clienteNombre} ha sido ${_estado}.`
                  );
                }
              }
            })
            .catch((error) => {});
        }
        _result = new APIResponse({
          success: true,
          message: "Voto registrado",
        });
      })
      .catch((error: any) => {
        _result = new APIResponse({
          success: false,
          message: error,
        });
      });

    return _result;
  }

  async _getEstado(_votos: Voto[]): Promise<string> {
    // ELIMINAR EL VOTO DEL APROBADOR NIVEL 1, NO CUENTA PARA LA DESICIÓN FINAL
    _votos = _votos.filter(
      (_voto: Voto) => _voto.usuarioPerfil !== Global.PERFILES[1]
    );

    let _estado: string = Global.ESTADOS[1];
    // AL LLEGAR AQUÍ, HABRÁN COMO MÍNIMO 3 VOTOS
    let SI: number = 0;
    let NO: number = 0;
    let PENDIENTE: number = 0;

    if (_votos.length === 0) {
      _estado = Global.ESTADOS[1];
    } else {
      _votos.forEach((_voto: Voto) => {
        _voto.voto === 1 ? SI++ : _voto.voto === 0 ? NO++ : PENDIENTE++;
      });

      // SI HAY 3 O MÁS VOTOS POSITIVOS, SE APRUEBA
      if (SI >= 3) {
        _estado = Global.ESTADOS[2];
      }

      // SI HAY 3 O MÁS VOTOS NEGATIVOS, SE RECHAZA
      if (NO >= 3) {
        _estado = Global.ESTADOS[3];
      }

      // SI HAY UN EMPATE Y YA NO FALTAN MÁS VOTOS, SE ANULA
      if (_votos.length === 4) {
        if (SI === NO && PENDIENTE === 0) {
          _estado = Global.ESTADOS[4];
        }
      }
    }
    // _solicitud.solicitudEstado = _estado;
    // this._updateSolicitud(_solicitud);
    return _estado;
  }

  async _getVotos(_solicitud: Solicitud): Promise<APIResponse> {
    let _result: APIResponse = new APIResponse({});
    let _votos: Voto[] = [];
    await get(child(ref(getDatabase()), `votos/${_solicitud.solicitudId}`))
      .then((_snapshot: DataSnapshot) => {
        if (_snapshot.exists()) {
          _snapshot.forEach((_documents: any) => {
            let _voto: Voto = new Voto(_documents.val());
            _voto.codigo = _documents.ref._path.pieces_[2];
            _votos.push(_voto);
          });
          _result = new APIResponse({
            success: true,
            data: _votos,
          });
        } else {
          _result = new APIResponse({
            success: false,
            message: "Aún no hay votos para esta solicitud",
          });
        }
      })
      .catch((_error) => {
        _result = new APIResponse({
          success: false,
          message: "Hubo un error al obtener los votos de la solicitud",
        });
      });

    return _result;
  }

  async _updateSolicitud(_solicitud: Solicitud): Promise<APIResponse> {
    let _result: APIResponse = new APIResponse({});
    await set(
      ref(
        getDatabase(),
        `solicitudes/${_solicitud.creadorCodigo}/${_solicitud.solicitudId}`
      ),
      _solicitud._toMap()
    )
      .then(() => {
        _result = new APIResponse({
          success: true,
        });
      })
      .catch((_error) => {
        _result = new APIResponse({
          success: false,
          message: _error,
        });
      });
    return _result;
  }

  async _verificarVotoUsuario(
    _solicitud: string,
    _usuario: string
  ): Promise<boolean> {
    let _existe: boolean = false;
    await get(child(ref(getDatabase()), `votos/${_solicitud}`))
      .then((_snapshot: DataSnapshot) => {
        if (_snapshot.exists()) {
          _snapshot.forEach((_documents: any) => {
            let _voto: Voto = new Voto(_documents.val());
            _voto.codigo = _documents.ref._path.pieces_[2];
            if (_voto.usuarioCodigo === _usuario) {
              _existe = true;
              return;
            }
          });
        } else {
          _existe = false;
        }
      })
      .catch((_error) => {
        _existe = false;
      });

    return _existe;
  }

  async _verificarVotoNivel(
    _solicitud: string,
    _perfil: string
  ): Promise<boolean> {
    let _existe: boolean = false;
    await get(child(ref(getDatabase()), `votos/${_solicitud}`))
      .then((_snapshot: DataSnapshot) => {
        if (_snapshot.exists()) {
          _snapshot.forEach((_documents: any) => {
            let _voto: Voto = new Voto(_documents.val());
            _voto.codigo = _documents.ref._path.pieces_[2];
            if (_perfil !== Global.PERFILES[4]) {
              if (_voto.usuarioPerfil === _perfil) {
                _existe = true;
                return;
              }
            }
          });
        } else {
          _existe = false;
        }
      })
      .catch((_error) => {
        _existe = false;
      });

    return _existe;
  }

  async _getToken({
    _uid,
    _perfil,
  }: {
    _uid?: string;
    _perfil?: string;
  }): Promise<string> {
    let _tokens: Token[] = [];
    let _tokenfcm: string = "";
    await get(child(ref(getDatabase()), `tokens/`))
      .then((_snapshot: DataSnapshot) => {
        if (_snapshot.exists()) {
          _snapshot.forEach((_data: any) => {
            let _token: Token = new Token(_data.val());
            _token.codigo = _data.ref._path.pieces_[2];
            _tokens.push(_token);
          });
          _tokens.forEach((_token: Token) => {
            if (_perfil) {
              if (_token.perfil === _perfil) {
                _tokenfcm = _token.token;
                return;
              }
            } else {
              if (_token.usuario === _uid) {
                _tokenfcm = _token.token;
                return;
              }
            }
          });
        }
      })
      .catch((_error) => {});

    return _tokenfcm;
  }

  async _sendNotification(
    _token: string,
    _title: string,
    _body: string,
    _data?: any
  ): Promise<void> {
    axios.post(
      Global.URL_NOTIFICATIONS,
      {
        to: _token,
        notification: {
          title: _title,
          body: _body,
          mutable_content: false,
          image: "https://www.3aamseq.com.pe/srss_cotizaciones/logo.png",
        },
        data: _data,
        webpush: {
          headers: {
            Urgency: "high",
          },
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: Global.API_KEY,
        },
      }
    );
  }
}

export interface TokenDataI {
  codigo?: string | null;
  fechaCreacion: string;
  perfil: string;
  token: string;
  usuario: string;
}

export class Token implements TokenDataI {
  codigo?: string | null;
  fechaCreacion: string;
  perfil: string;
  token: string;
  usuario: string;

  constructor(data: TokenDataI) {
    this.codigo = data.codigo;
    this.fechaCreacion = data.fechaCreacion;
    this.perfil = data.perfil;
    this.token = data.token;
    this.usuario = data.usuario;
  }
}

import { Col } from "antd";
import { Form } from "antd";
import { FormInstance } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Row } from "antd";
import { Solicitud } from "../../model/solicitud";
import Global from "../../global/global";

function InformacionSolicitud({
  _solicitud,
  _formSolicitud,
  _renderPDF,
}: {
  _solicitud: Solicitud;
  _formSolicitud: FormInstance;
  _renderPDF: Function;
}) {
  return (
    <Fragment>
      <Form
        form={_formSolicitud}
        name="form-solicitud"
        layout="vertical"
        autoComplete="off"
      >
        <Row>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item label="Fecha" name="solicitudFechaCreacion">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={3} xxl={3}>
            <Form.Item label="Estado">
              {Global._renderEstado(_solicitud.solicitudEstado!)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={3} xxl={3}>
            <Form.Item label="Tipo">
              {Global._renderTipo(_solicitud.solicitudTipo!)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item label="Tipo de garant&iacute;a" name="solicitudGarantia">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item
              label="Condici&oacute;n de pago"
              name="solicitudCondicionPago"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item label="Plazo (d&iacute;as)" name="solicitudPlazo">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item label="Porcentaje" name="solicitudPorcentaje">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item
              label="Total cotizaci&oacute;n"
              name="solicitudTotalCotizacion"
            >
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Form.Item label="Detalle de la cotizaci&oacute;n">
              {_renderPDF(
                _solicitud.solicitudPDFCotizacion!,
                "Detalle de la cotización"
              )}
            </Form.Item>
          </Col>
          {_solicitud.solicitudObservaciones ? (
            <Col xs={24} sm={12} md={12} lg={24} xl={24} xxl={24}>
              <Form.Item label="Observaciones" name="solicitudObservaciones">
                <Input.TextArea readOnly />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Form>
    </Fragment>
  );
}

export default InformacionSolicitud;

/* eslint-disable jsx-a11y/alt-text */
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Modal } from "antd";
import { PDFViewer } from "@react-pdf/renderer";
import { Row } from "antd";
import { Solicitud } from "../../model/solicitud";
import { Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Voto } from "../../model/voto";
import AgregarInforme from "./agregar_informe";
import AgregarObservaciones from "./agregar_observaciones";
import GenerarPDF from "./generar_pdf";
import LeerPDF from "./leer_pdf";

function InformeFinal({
  _solicitud,
  _votos,
  _guardarObservaciones,
  _subirInformeFinal,
  _isMobile,
}: {
  _solicitud: Solicitud;
  _votos: Voto[];
  _guardarObservaciones: Function;
  _subirInformeFinal: Function;
  _isMobile: boolean;
}) {
  const [_formObservaciones] = Form.useForm();
  const [_formInformeFinal] = Form.useForm();
  const [_visibleObservaciones, _setVisibleObservaciones] =
    useState<boolean>(false);
  const [_visibleInformeFinal, _setVisibleInformeFinal] =
    useState<boolean>(false);

  return (
    <Fragment>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-center col-padding-detalle-solicitud"
        >
          <Space direction={_isMobile ? "vertical" : "horizontal"}>
            {_solicitud.solicitudInformeFinal ? (
              ""
            ) : (
              <Button
                onClick={() => {
                  _setVisibleInformeFinal(true);
                }}
                icon={<UploadOutlined />}
              >
                Subir archivo firmado
              </Button>
            )}

            {_solicitud.solicitudObservaciones ? (
              ""
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  _setVisibleObservaciones(true);
                }}
              >
                Agregar observaciones
              </Button>
            )}
          </Space>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-center"
        >
          {_solicitud.solicitudInformeFinal ? (
            <LeerPDF _url={_solicitud.solicitudInformeFinal} />
          ) : (
            <PDFViewer style={{ width: "100%", height: "50vh" }}>
              <GenerarPDF _solicitud={_solicitud} _votos={_votos} />
            </PDFViewer>
          )}
        </Col>
      </Row>

      {/* Modal para escribir las observaciones */}
      <Modal
        title="Observaciones"
        visible={_visibleObservaciones}
        footer={[]}
        className="modal-voto-detalle-solicitud"
        onCancel={() => {
          _formObservaciones.resetFields();
          _setVisibleObservaciones(false);
        }}
        centered
        maskClosable={false}
        closable={false}
      >
        <AgregarObservaciones
          _formObservaciones={_formObservaciones}
          _guardarObservaciones={_guardarObservaciones}
          _setVisibleObservaciones={_setVisibleObservaciones}
        />
      </Modal>

      {/* Modal para subir el informe final firmado */}
      <Modal
        title="Subir informe final firmado"
        visible={_visibleInformeFinal}
        footer={[]}
        className="modal-voto-detalle-solicitud"
        onCancel={() => {
          _formInformeFinal.resetFields();
          _setVisibleInformeFinal(false);
        }}
        centered
        maskClosable={false}
        closable={false}
      >
        <AgregarInforme
          _formInformeFinal={_formInformeFinal}
          _subirInformeFinal={_subirInformeFinal}
          _setVisibleInformeFinal={_setVisibleInformeFinal}
        />
      </Modal>
    </Fragment>
  );
}

export default InformeFinal;
